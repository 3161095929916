import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
//non-node imports

// import PrivateRoute from "./ProtectedRouter";

import Header from "./commonComponents/Header";
import Footer from "./commonComponents/Footer";
import Navbar from "./commonComponents/NavBarSecondary";

// Basic Routes
import Home from "./Home/Home";
import AboutUs from "./AboutUs/AboutUs";
import ContactUs from "./ContactUs/ContactUs";
import Faq from "./Faq/Faq";
import Support from "./Support/Support";
import OurPolicy from "./OurPolicy/OurPolicy";


//Auth Routes
import Login from "./Auth/Login";
import SignUp from "./Auth/SignUp";


//Profile Routes
import Profile from "./Profile/Profile";
import ProfileOrders from "./ProfileOrders/ProfileOrders";
import ChangePassword from "./ChangePassword/ChangePassword";


import TourList from "./TourList/TourList";
import ConfirmTicketDetails from "./ConfirmTicketDetails/ConfirmTicketDetails";
import SelectSeat from "./layouts"
import Payment from "./Payment/Payment";
import PaymentSuccess from "./Payment/PaymentSuccess"
import PaymentFailure from "./Payment/PaymentFailure"
import Invoice from "./Invoice/Invoice";

import Page404 from "./Page404/Page404";

function AppRoutes() {
  const [noHeader, setNoHeader] = useState(false);
  return (
    <>
      <Header />
      {/* <Navbar /> */}

      <Routes>
        {/* content paths change here */}

        {/* Basic Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/ourpolicy" element={<OurPolicy />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/support" element={<Support />} />

        {/* Auth Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />


        {/* Profile Routes */}
        <Route path="/profile" element={<Profile />} />
        <Route path="/profileOrders" element={<ProfileOrders />} />
        <Route path="/changePassword" element={<ChangePassword />} />



        <Route path="/toursList" element={<TourList />} />
        <Route
          path="/selectSeat"
          element={<SelectSeat />}
        />
        <Route
          path="/confirmTicketDetails"
          element={<ConfirmTicketDetails />}
        />
        <Route path="/payment" element={<Payment />} />
        <Route path="/paymentSuccess" element={<PaymentSuccess />} />
        <Route path="/paymentFailure" element={<PaymentFailure />} />
        <Route path="/invoice" element={<Invoice />} />
        {/* in case of route not found */}
        <Route path="*" element={<Page404 />} />


        




      </Routes>

      <Footer />
    </>
  );
}

export default AppRoutes;
