import Ticket from '../services/tickets';





export const getVacantSeats = (form) => {
    return Ticket.getVacantSeats(form).then(response => {
        return {status: response.status,data:response.data};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const getTicketFare = (form) => {
    return Ticket.getTicketFare(form).then(response => {
        return {status: response.status,data:response.data};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const createOneTicket = (form) => {
    return Ticket.createOneTicket(form).then(response => {
        return {status: response.status,data:response.data};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

// export const getAllCities = () => {

//     return Train.getAllCities().then(response => {
//         return {status: response.status,data:response.data, message:response.data.pp_ResponseMessage};
//     }).catch(e => {
//         return {status: e.response.status, message: e.response.data};
//     })

// };
// export const getTrainStartingCities = (train_id) => {

//     return Train.getTrainStartingCities(train_id).then(response => {
//         return {status: response.status,data:response.data, message:response.data.pp_ResponseMessage};
//     }).catch(e => {
//         return {status: e.response.status, message: e.response.data};
//     })

// };

// export const getTrainEndingCities = (train_id , st_city) => {

//     return Train.getTrainEndingCities(train_id , st_city).then(response => {
//         return {status: response.status,data:response.data, message:response.data.pp_ResponseMessage};
//     }).catch(e => {
//         return {status: e.response.status, message: e.response.data};
//     })

// };

// export const getTrainCoaches = (form) => {

//     return Train.getTrainCoaches(form).then(response => {
//         return {status: response.status,data:response.data, message:response.data.pp_ResponseMessage};
//     }).catch(e => {
//         return {status: e.response.status, message: e.response.data};
//     })

// };


// export const helloTest = () => {

//     return Payment.helloTest()

// };

