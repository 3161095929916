import React from 'react'

export default function AboutUs() {
  return (
    <div id="content">
    <div class="container">
      <div class="bg-white shadow-md rounded p-4">
        <h2 class="text-6">What is Train <span style={{color:'#F19C1A'}}>Travels</span> ?</h2>
        <p>Train Travels (TM) is a TradeMark of <strong>Imran Enterprises</strong> developed to provide users with the best in class travel experience on the trains powered by Imran Enterprises throughout the country.</p>
        <p>We are here to provide you with the easiest online services to book train tickets , travel across Pakistan with the comfort of your home. <strong>Train Travels</strong> being an empowered product by leading firm of Pakistan provides you the best in class booking experience , customer first support policy and easy refunds.</p>
        <p>We , based at the heart of Punjab are a lahore based enterprise serving the railway customers an extravagant experience from the past decades.</p>
        <div class="row mt-4 mb-2">
          <div class="col-md-4">
            <div class="featured-box style-1">
              <div class="featured-box-icon text-primary"> <i class="far fa-thumbs-up"></i></div>
              <h3>Why choose Us</h3>
              <p>Train Travels (TM) is a TradeMark of <strong>Imran Enterprises</strong> providing best in class Online
                        Train Ticket Booking services to users across multiple cities in Pakistan.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="featured-box style-1">
              <div class="featured-box-icon text-primary"> <i class="far fa-paper-plane"></i> </div>
              <h3>Our Mission</h3>
              <p>To provide with the ease of train bookings at home and best in class travel experience to our customers.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="featured-box style-1">
              <div class="featured-box-icon text-primary"> <i class="far fa-eye"></i> </div>
              <h3>Our Vision</h3>
              <p>Our Vision is to  deliver excellent quality service to our clients. Providing them unforgettable, enjoyable travel, with guaranteed services that exceed their expectations.</p>
            </div>
          </div>
        </div>
        <h2 class="text-6 mb-3">Leadership</h2>
        <div class="row">
          <div class="col-sm-6 col-md-4">
            <div class="team"> <img class="img-fluid rounded" alt="" src="images/team/team-ie.jpg" />
              <h3>Imran Enterprises</h3>
              <p class="text-muted">Parent Company</p>
              
            </div>
          </div>
          {/* <div class="col-sm-6 col-md-3">
            <div class="team"> <img class="img-fluid rounded" alt="" src="images/team/leader-2.jpg" />
              <h3>James Maxwell</h3>
              <p class="text-muted">Co-Founder</p>
              <ul class="social-icons social-icons-sm d-inline-flex">
                <li class="social-icons-facebook"><a data-bs-toggle="tooltip" href="http://www.facebook.com/" target="_blank" title="Facebook"><i class="fab fa-facebook-f"></i></a></li>
                <li class="social-icons-twitter"><a data-bs-toggle="tooltip" href="http://www.twitter.com/" target="_blank" title="Twitter"><i class="fab fa-twitter"></i></a></li>
                <li class="social-icons-google"><a data-bs-toggle="tooltip" href="http://www.google.com/" target="_blank" title="Google"><i class="fab fa-google"></i></a></li>
              </ul>
            </div>
          </div> */}
          <div class="col-sm-6 col-md-4">
            <div class="team"> <img class="img-fluid rounded" alt="" src="images/team/taha-photo2.jpeg" />
              <h3>Taha Bhatti</h3>
              <p class="text-muted">Co-Founder</p>
              <ul class="social-icons social-icons-sm d-inline-flex">
                <li class="social-icons-facebook"><a data-bs-toggle="tooltip" href="http://www.facebook.com/" target="_blank" title="Facebook"><i class="fab fa-facebook-f"></i></a></li>
          
              </ul>
            </div>
          </div>
          <div class="col-sm-6 col-md-4">
            <div class="team"> <img class="img-fluid rounded" alt="" src="images/team/user-icon.jpg" />
              <h3>Hassan Yousaf</h3>
              <p class="text-muted">Product Developer</p>
              <ul class="social-icons social-icons-sm d-inline-flex">
                <li class="social-icons-facebook"><a data-bs-toggle="tooltip" href="http://www.facebook.com/" target="_blank" title="Facebook"><i class="fab fa-facebook-f"></i></a></li>
                
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
