import React from 'react'

export default function ConfirmTicketDetails() {
  return (
    <div id="content">
    <section class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="bg-white shadow-md rounded p-3 p-sm-4 confirm-details">
            <h2 class="text-6 mb-3 mb-sm-4">Confirm Trains Details</h2>
            <hr class="mx-n3 mx-sm-n4 mb-4" />
            <div class="card">
              <div class="card-header">
                <div class="row align-items-center trip-title">
                  <div class="col-5 col-sm-auto text-center text-sm-start">
                    <h5 class="m-0 trip-place">Delhi</h5>
                  </div>
                  <div class="col-2 col-sm-auto text-8 text-black-50 text-center trip-arrow">➝</div>
                  <div class="col-5 col-sm-auto text-center text-sm-start">
                    <h5 class="m-0 trip-place">Ahmedabad</h5>
                  </div>
                  <div class="col-12 mt-1 d-block d-md-none"></div>
                  <div class="col-6 col-sm col-md-auto text-3 date">15 Jun 18, Sat</div>
                  <div class="col-6 col-sm col-md-auto text-end order-sm-1"><a class="text-1" data-bs-toggle="modal" data-bs-target="#fare-rules"  href="#">Fare Rules</a></div>
                  <div class="col col-md-auto text-center ms-auto order-sm-0"><span class="badge bg-success fw-normal text-1">Refundable</span></div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-sm-3 text-center text-md-start"> <span class="text-4 text-dark train-name">Ashram Express</span> <span class="text-muted d-block">12916</span> </div>
                  <div class="col-12 col-sm-3 text-center time-info mt-3 mt-sm-0"> <span class="text-5 text-dark">23:00</span> <small class="text-muted d-block">Delhi Jn</small> <small class="text-muted d-block">15 Jun 18, Sat</small> </div>
                  <div class="col-12 col-sm-3 text-center time-info mt-3 mt-sm-0"> <span class="text-3 duration">18h 55m</span> <small class="text-muted d-block">35 Stops</small> </div>
                  <div class="col-12 col-sm-3 text-center time-info mt-3 mt-sm-0"> <span class="text-5 text-dark">18:15</span> <small class="text-muted d-block">Ahmedabad Jn</small> <small class="text-muted d-block">16 Jun 18, Sun</small> </div>
                  <div class="col-12 mt-3 text-dark text-center text-md-start">Class: <span class="bg-info badge text-2 fw-normal">First Class</span></div>
                </div>
                
                {/* <!-- Fare Rules Modal Dialog
                  ============================================= --> */}
                <div id="fare-rules" class="modal fade" role="dialog" aria-hidden="true">
                  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title">Fare Rules</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                          <li class="nav-item"> <a class="nav-link active" id="third-tab" data-bs-toggle="tab" href="#third" role="tab" aria-controls="third" aria-selected="false">Baggage Details</a> </li>
                          <li class="nav-item"> <a class="nav-link" id="fourth-tab" data-bs-toggle="tab" href="#fourth" role="tab" aria-controls="fourth" aria-selected="false">Cancellation Fee</a> </li>
                        </ul>
                        <div class="tab-content my-3" id="myTabContent">
                          <div class="tab-pane fade show active" id="third" role="tabpanel" aria-labelledby="third-tab">
                            <div class="table-responsive-md">
                              <table class="table table-hover table-bordered">
                                <thead>
                                  <tr>
                                    <th>&nbsp;</th>
                                    <td class="text-center">Cabin</td>
                                    <td class="text-center">Check-In</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Adult</td>
                                    <td class="text-center">7 Kg</td>
                                    <td class="text-center">15 Kg</td>
                                  </tr>
                                  <tr>
                                    <td>Child</td>
                                    <td class="text-center">7 Kg</td>
                                    <td class="text-center">15 Kg</td>
                                  </tr>
                                  <tr>
                                    <td>Infant</td>
                                    <td class="text-center">0 Kg</td>
                                    <td class="text-center">0 Kg</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div class="tab-pane fade" id="fourth" role="tabpanel" aria-labelledby="fourth-tab">
                            <table class="table table-hover table-bordered">
                              <thead>
                                <tr>
                                  <th>&nbsp;</th>
                                  <td class="text-center">Per Passenger Fee</td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>24 hrs - 365 days</td>
                                  <td class="text-center">$250 + $50</td>
                                </tr>
                                <tr>
                                  <td>2-24 hours</td>
                                  <td class="text-center">$350 + $50</td>
                                </tr>
                                <tr>
                                  <td>0-2 hours</td>
                                  <td class="text-center">$550 + $50</td>
                                </tr>
                              </tbody>
                            </table>
                            <p class="fw-bold">Terms & Conditions</p>
                            <ul>
                              <li>The penalty is subject to 4 hrs before departure. No Changes are allowed after that.</li>
                              <li>The charges are per passenger per sector.</li>
                              <li>Partial cancellation is not allowed on tickets booked under special discounted fares.</li>
                              <li>In case of no-show or ticket not cancelled within the stipulated time, only statutory taxes are refundable subject to Service Fee.</li>
                              <li>No Baggage Allowance for Infants</li>
                              <li>Airline penalty needs to be reconfirmed prior to any amendments or cancellation.</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Fare Rules Modal Dialog end -->  */}
                
              </div>
            </div>
            <div class="alert alert-info mt-4"> <span class="badge bg-info">NOTE:</span> This is a special fare given by the railway. Railway cancellation charges do apply. </div>
            <h2 class="text-6 mb-3 mt-5">Traveller Details - <span class="text-3"><a data-bs-toggle="modal" data-bs-target="#login-signup" href="#" title="Login / Sign up">Login</a> to book faster</span></h2>
            <p class="fw-600">Contact Details</p>
            <div class="row g-3 mb-3">
              <div class="col-sm-6">
                <input class="form-control" id="email" required placeholder="Enter Email" type="text" />
              </div>
              <div class="col-sm-6">
                <input class="form-control" data-bv-field="number" id="mobileNumber" required placeholder="Enter Mobile Number" type="text" />
              </div>
            </div>
            <p class="text-info">Your booking details will be sent to this email address and mobile number.</p>
            <p class="fw-600">Adult 1</p>
            <div class="row g-3">
              <div class="col-sm-2">
                <select class="form-select" id="title" required="">
                  <option value="">Title</option>
                  <option>Mr</option>
                  <option>Ms</option>
                  <option>Mrs</option>
                </select>
              </div>
              <div class="col-sm-5 form-group">
                <input class="form-control" id="firstName" required placeholder="Enter First Name" type="text" />
              </div>
              <div class="col-sm-5 form-group">
                <input class="form-control" data-bv-field="number" id="lastName" required placeholder="Enter Last Name" type="text" />
              </div>
            </div>
          </div>
        </div>
        
        {/* <!-- Side Panel (Fare Details)
          ============================================= --> */}

        <aside class="col-lg-4 mt-4 mt-lg-0">
          <div class="bg-white shadow-md rounded p-3">
            <h3 class="text-5 mb-3">Fare Details</h3>
            <hr class="mx-n3" />
            <ul class="list-unstyled">
              <li class="mb-2">Base Fare <span class="float-end text-4 fw-500 text-dark">$980</span><br />
                <small class="text-muted">Adult : 1, Child : 0, Infant : 0</small></li>
              <li class="mb-2">Taxes & Fees <span class="float-end text-4 fw-500 text-dark">$215</span></li>
              <li class="mb-2">Insurance <span class="float-end text-4 fw-500 text-dark">$95</span></li>
            </ul>
            <div class="text-dark bg-light-4 text-4 fw-600 p-3"> Total Amount <span class="float-end text-6">$1290</span> </div>
            <h3 class="text-4 mb-3 mt-4">Promo Code</h3>
            <div class="input-group mb-4">
              <input class="form-control" placeholder="Promo Code" aria-label="Promo Code" type="text" />
              <button class="btn btn-secondary shadow-none px-3" type="submit">APPLY</button>
            </div>
            <ul class="promo-code">
              <li><span class="d-block text-3 fw-600">FLTOFFER</span>Up to $500 Off on your booking. Hurry! Limited period offer. <a class="text-1" href="#">Terms & Conditions</a></li>
              <li><span class="d-block text-3 fw-600">HOTOFFER</span>Up to $500 Off on your booking. Hurry! Limited period offer. <a class="text-1" href="#">Terms & Conditions</a></li>
              <li><span class="d-block text-3 fw-600">SUMMEROFFER</span>Up to $500 Off on your booking. Hurry! Limited period offer. <a class="text-1" href="#">Terms & Conditions</a></li>
              <li><span class="d-block text-3 fw-600">BIGOFFER</span>Up to $500 Off on your booking. Hurry! Limited period offer. <a class="text-1" href="#">Terms & Conditions</a></li>
              <li><span class="d-block text-3 fw-600">FLTOFFER</span>Up to $500 Off on your booking. Hurry! Limited period offer. <a class="text-1" href="#">Terms & Conditions</a></li>
              <li><span class="d-block text-3 fw-600">FLTOFFER</span>Up to $500 Off on your booking. Hurry! Limited period offer. <a class="text-1" href="#">Terms & Conditions</a></li>
            </ul>
            <div class="d-grid">
              <button class="btn btn-primary" onclick="location.href='payment.html';" type="submit">Proceed To Payment</button>
            </div>
          </div>
        </aside>
        {/* <!-- Side Panel end -->  */}
        
      </div>
    </section>
  </div>
  )
}
