import Customer from '../services/customers';





export const sendOtp = (form) => {
    return Customer.sendOtp(form).then(response => {
        return {status: response.status,data:response.data};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data.message};
    })

};

export const verifyOtp = (form) => {
    return Customer.verifyOtp(form).then(response => {
        return {status: response.status,data:response.data};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data.message};
    })

};



