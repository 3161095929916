import React, { useState, useEffect } from "react";
import "./seats.css";
import "./responsive-seats.css";
import $ from "jquery";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// custom action imports
import { getTicketFare, getVacantSeats } from "../../../actions/tickets.actions";
import { getTrainServiceFee } from "../../../actions/trains.actions";


export default function ConfirmTicketDetails({ coachData }) {
  const navigate  = useNavigate()
  const [clickedSeat, setClickedSeat] = useState("");
  const [isSeatModalOpen, SetisSeatModalOpen] = useState(false);
  const [bookedSeats, setBookedSeats] = useState([]);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [ticketFare, setTicketFare] = useState(0);
  const [serviceFee, setServiceFee] = useState(50);

  // Utility Functions start
  const removeSelectedSeat = (element) => {
    setSelectedSeats((current) =>
      current.filter((seat) => seat !== element)
    );
  };

  function markBookedSeatsRed() {
    
    for (let seat of bookedSeats) {
      $(`label[for="${seat}"]`).addClass("red");
    }
    
  }

  const handleSeatClick = (seat_number) => {
    setClickedSeat(seat_number);
    SetisSeatModalOpen(true);
    setTimeout(() => {
      SetisSeatModalOpen(false);
    }, 200);
    if (selectedSeats.includes(seat_number)) {
      removeSelectedSeat(seat_number);
    } else {
      if (selectedSeats.length === 8) {
        toast.error("You can select 8 seats at max.");
        $(`#${seat_number}`).prop("checked", false);
      } else {
        setSelectedSeats((prevValue) => {
          return [...prevValue, seat_number];
        });
      }
    }
  };

  function proceedToPayment(){
    if(selectedSeats.length > 0 && ticketFare > 0){
      navigate("/payment" , {state: {
        ticketFare,
        serviceFee,
        seats: selectedSeats,
        train_id: coachData.train_id,
        st_city: coachData.st_city,
        end_city: coachData.ending_city,
        tc_id: coachData.id
      }
      });
    }else{
      toast.error("Please select a seat")
    }
    
  }
  // Utility Functions end

  // Fetch Functions Start
  async function fetchTicketFare() {
    let resp = await getTicketFare({
      train_id: coachData.train_id,
      st_city: coachData.st_city,
      end_city: coachData.ending_city,
      seat_number: selectedSeats.join(","),
      tc_id: coachData.id,
    });
    if (resp.status === 200) {
      setTicketFare(resp.data.fare);
    } else {
      toast.error("Unable to fetch fares at the moment.");
      setTicketFare(0);
    }
  }
  async function fetchVacantSeats() {
    let resp = await getVacantSeats({
      tc_id: coachData.id,
      st_city: coachData.st_city,
      end_city: coachData.ending_city,
    });
    if(resp.status  === 200){
      let arr2 = resp.data.difference;
      let difference = layoutSeats.filter((x) => !arr2.includes(x));
      setBookedSeats(difference);
      // alert(difference.length)
    }else{
      toast.error("Unable to book seat at the moment. Please try again.")
      navigate("/")
    }
    $("#preloader").delay(333).fadeOut("slow");
  }

  async function fetchTrainServiceFee(){
    let resp = await getTrainServiceFee(coachData.train_id)
    if(resp.status  === 200){
      setServiceFee(Number(resp.data.value))
    }else{
      toast.error("Unable to book seat at the moment. Please try again.")
      navigate("/")
    }
  }
  //Fetch Functions End

  useEffect(() => {
    fetchVacantSeats()
    fetchTrainServiceFee()
    $("#preloader").delay(333).fadeIn("slow");
  }, []);

  useEffect(() => {
    if (selectedSeats.length > 0) {
      fetchTicketFare();
    }else{
      setTicketFare(0)
    }
    
  }, [selectedSeats]);

  useEffect(() => {
    if (bookedSeats.length > 0) {
      markBookedSeatsRed();
    }
  }, [bookedSeats]);

  return (
    <div id="content">
      {/* <!-- Seat Number Modal --> */}
      <div
        className="uniqclvalue"
        style={{ display: isSeatModalOpen ? "flex" : "none" }}
        id="uniqclvalue"
      >
        <h4 id="displayvalue">{clickedSeat}</h4>
      </div>
      {/* <!-- Seat Number Modal End -->  */}
      <section className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="bg-white shadow-md rounded p-3 p-sm-4 confirm-details">
              <h2 className="text-6 mb-3 mb-sm-4">Confirm Trains Details</h2>
              <hr className="mx-n3 mx-sm-n4 mb-4" />
              <div class="custom-seats acstd98">
                <div class="seats-header">
                  <div class="seat-heading">
                    <h2 class="path">Path</h2>
                  </div>
                </div>
                <div class="path-line">
                  <div class="box1"></div>
                  <div class="box2"></div>
                </div>
                <div class="entrance-box">
                  <div class="box1">
                    <h3 class="entrance">ENTRANCE 98</h3>
                  </div>
                  <div class="box2"></div>
                </div>
                <div class="cabin-box">
                  <div class="box"></div>
                  <div class="box">
                    <h3 class="cabin">Cabin 1</h3>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("17S")}
                        id="17S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="17S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        <div class="clvalue">17S</div>
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("1C1S")}
                        id="1C1S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="1C1S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        1C1S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("1C2S")}
                        id="1C2S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="1C2S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        1C2S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("1C3S")}
                        id="1C3S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="1C3S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        1C3S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("1C4S")}
                        id="1C4S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="1C4S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        1C4S
                      </label>
                    </div>
                    {/* <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("1C1B")}
                        id="1C1B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="1C1B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        1C1B
                      </label>
                    </div> */}
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("18S")}
                        id="18S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="18S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        18S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("1C5S")}
                        id="1C5S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="1C5S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        1C5S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("1C6S")}
                        id="1C6S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="1C6S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        1C6S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("1C7S")}
                        id="1C7S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="1C7S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        1C7S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("1C8S")}
                        id="1C8S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="1C8S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        1C8S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("1C2B")}
                        id="1C2B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="1C2B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        1C2B
                      </label>
                    </div>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("19S")}
                        id="19S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="19S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        19S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="cabin-box">
                  <div class="box"></div>
                  <div class="box">
                    <h3 class="cabin">Cabin 2</h3>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("20S")}
                        id="20S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="20S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        20S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("2C3B")}
                        id="2C3B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="2C3B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        2C3B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("2C4B")}
                        id="2C4B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="2C4B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        2C4B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("2C5B")}
                        id="2C5B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="2C5B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        2C5B
                      </label>
                    </div>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("53B")}
                        id="53B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="53B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        53B
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("2C6B")}
                        id="2C6B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="2C6B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        2C6B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("2C7B")}
                        id="2C7B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="2C7B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        2C7B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("2C8B")}
                        id="2C8B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="2C8B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        2C8B
                      </label>
                    </div>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("21S")}
                        id="21S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="21S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        21S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="cabin-box">
                  <div class="box"></div>
                  <div class="box">
                    <h3 class="cabin">Cabin 3</h3>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("22S")}
                        id="22S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="22S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        22S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("3C9B")}
                        id="3C9B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="3C9B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        3C9B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("3C10B")}
                        id="3C10B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="3C10B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        3C10B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("3C11B")}
                        id="3C11B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="3C11B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        3C11B
                      </label>
                    </div>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("23S")}
                        id="23S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="23S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        23S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("3C12B")}
                        id="3C12B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="3C12B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        3C12B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("3C13B")}
                        id="3C13B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="3C13B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        3C13B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("3C14B")}
                        id="3C14B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="3C14B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        3C14B
                      </label>
                    </div>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("54B")}
                        id="54B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="54B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        54B
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="cabin-box">
                  <div class="box"></div>
                  <div class="box">
                    <h3 class="cabin">Cabin 4</h3>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("24S")}
                        id="24S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="24S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        24S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("4C15B")}
                        id="4C15B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="4C15B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        4C15B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("4C16B")}
                        id="4C16B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="4C16B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        4C16B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("4C17B")}
                        id="4C17B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="4C17B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        4C17B
                      </label>
                    </div>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("25S")}
                        id="25S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="25S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        25S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("4C18B")}
                        id="4C18B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="4C18B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        4C18B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("4C19B")}
                        id="4C19B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="4C19B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        4C19B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("4C20B")}
                        id="4C20B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="4C20B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        4C20B
                      </label>
                    </div>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("26S")}
                        id="26S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="26S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        26S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="cabin-box">
                  <div class="box"></div>
                  <div class="box">
                    <h3 class="cabin">Cabin 5</h3>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("55B")}
                        id="55B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="55B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        55B
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("5C21B")}
                        id="5C21B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="5C21B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        5C21B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("5C22B")}
                        id="5C22B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="5C22B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        5C22B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("5C23B")}
                        id="5C23B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="5C23B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        5C23B
                      </label>
                    </div>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("27S")}
                        id="27S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="27S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        27S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("5C24B")}
                        id="5C24B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="5C24B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        5C24B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("5C25B")}
                        id="5C25B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="5C25B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        5C25B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("5C26B")}
                        id="5C26B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="5C26B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        5C26B
                      </label>
                    </div>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("28S")}
                        id="28S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="28S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        28S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="cabin-box">
                  <div class="box"></div>
                  <div class="box">
                    <h3 class="cabin">Cabin 6</h3>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("29S")}
                        id="29S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="29S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        29S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("6C27B")}
                        id="6C27B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="6C27B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        6C27B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("6C28B")}
                        id="6C28B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="6C28B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        6C28B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("6C29B")}
                        id="6C29B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="6C29B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        6C29B
                      </label>
                    </div>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("56B")}
                        id="56B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="56B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        56B
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("6C30B")}
                        id="6C30B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="6C30B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        6C30B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("6C31B")}
                        id="6C31B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="6C31B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        6C31B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("6C32B")}
                        id="6C32B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="6C32B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        6C32B
                      </label>
                    </div>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("30S")}
                        id="30S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="30S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        30S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="cabin-box">
                  <div class="box"></div>
                  <div class="box">
                    <h3 class="cabin">Cabin 7</h3>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("31S")}
                        id="31S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="31S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        31S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("7C33B")}
                        id="7C33B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="7C33B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        7C33B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("7C34B")}
                        id="7C34B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="7C34B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        7C34B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("7C35B")}
                        id="7C35B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="7C35B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        7C35B
                      </label>
                    </div>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("32S")}
                        id="32S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="32S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        32S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("7C36B")}
                        id="7C36B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="7C36B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        7C36B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("7C37B")}
                        id="7C37B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="7C37B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        7C37B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("7C38B")}
                        id="7C38B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="7C38B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        7C38B
                      </label>
                    </div>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("57B")}
                        id="57B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="57B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        57B
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("33S")}
                        id="33S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="33S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        33S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="cabin-box">
                  <div class="box"></div>
                  <div class="box">
                    <h3 class="cabin">Cabin 8</h3>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("34S")}
                        id="34S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="34S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        34S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("8C39B")}
                        id="8C39B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="8C39B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        8C39B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("8C40B")}
                        id="8C40B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="8C40B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        8C40B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("8C41B")}
                        id="8C41B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="8C41B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        8C41B
                      </label>
                    </div>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("35S")}
                        id="35S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="35S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        35S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("8C42B")}
                        id="8C42B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="8C42B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        8C42B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("8C43B")}
                        id="8C43B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="8C43B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        8C43B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("8C44B")}
                        id="8C44B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="8C44B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bud"
                            class="img-fluid"
                          />
                        </div>
                        8C44B
                      </label>
                    </div>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("58B")}
                        id="58B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="58B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        58B
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("36S")}
                        id="36S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="36S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        36S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="cabin-box">
                  <div class="box"></div>
                  <div class="box">
                    <h3 class="cabin">Cabin 9</h3>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("37S")}
                        id="37S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="37S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        37S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("9C45B")}
                        id="9C45B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="9C45B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        9C45B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("9C46B")}
                        id="9C46B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="9C46B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        9C46B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("9C47B")}
                        id="9C47B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="9C47B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        9C47B
                      </label>
                    </div>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("38S")}
                        id="38S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="38S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        38S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("9C48B")}
                        id="9C48B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="9C48B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        9C48B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("9C49B")}
                        id="9C49B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="9C49B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        9C49B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("9C50B")}
                        id="9C50B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="9C50B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        9C50B
                      </label>
                    </div>
                  </div>
                </div>
                <div class="cabin-box">
                  <div class="box"></div>
                  <div class="box">
                    <h3 class="cabin">Cabin 10</h3>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("59B")}
                        id="59B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="59B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        59B
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("10C9S")}
                        id="10C9S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="10C9S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        10C9S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("10C10S")}
                        id="10C10S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="10C10S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        10C10S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("10C11S")}
                        id="10C11S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="10C11S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        10C11S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("10C12S")}
                        id="10C12S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="10C12S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        10C12S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("10C51B")}
                        id="10C51B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="10C51B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        10C51B
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("39S")}
                        id="39S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="39S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        39S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("10C13S")}
                        id="10C13S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="10C13S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        10C13S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("10C14S")}
                        id="10C14S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="10C14S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        10C14S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("10C15S")}
                        id="10C15S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="10C15S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        10C15S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("10C16S")}
                        id="10C16S"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="10C16S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        10C16S
                      </label>
                    </div>
                    {/* <div class="seat">
                      <input
                        class="cl-custom-check"
                        onClick={() => handleSeatClick("10C52B")}
                        id="10C52B"
                        type="checkbox"
                      />
                      <label
                        class="cl-custom-check-label"
                        for="10C52B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="bed"
                            class="img-fluid"
                          />
                        </div>
                        10C52B
                      </label>
                    </div> */}
                  </div>
                  <div class="seat-box"></div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Side Panel (Fare Details)
          ============================================= --> */}

          <aside className="col-lg-4 mt-4 mt-lg-0">
            <div className="bg-white shadow-md rounded p-3">
              <h3 className="text-5 mb-3">Fare Details</h3>
              <hr className="mx-n3" />
              <ul className="list-unstyled">
                <li className="mb-2">
                  Selected Seats
                  <span className="float-end text-3 fw-500 text-dark">
                    {selectedSeats.join(", ")}
                  </span>
                  <br />
                  <small className="text-muted">{selectedSeats.length}</small>
                </li>
                <li className="mb-2">
                  Base Fare
                  <span className="float-end text-4 fw-500 text-dark">
                    PKR{ticketFare}
                  </span>
                </li>
                <li className="mb-2">
                  Service Fee{" "}
                  <span className="float-end text-4 fw-500 text-dark">
                    PKR{(selectedSeats.length > 0 ? serviceFee : 0)}
                  </span>
                </li>
              </ul>
              <div className="text-dark bg-light-4 text-4 fw-600 p-3">
                {" "}
                Total Amount{" "}
                <span className="float-end text-6">
                  PKR{ticketFare + (selectedSeats.length > 0 ? serviceFee : 0)}
                </span>{" "}
              </div>
              {/* <h3 className="text-4 mb-3 mt-4">Promo Code</h3>
              <div className="input-group mb-4">
                <input
                  className="form-control"
                  placeholder="Promo Code"
                  aria-label="Promo Code"
                  type="text"
                />
                <button
                  className="btn btn-secondary shadow-none px-3"
                  type="submit"
                >
                  APPLY
                </button>
              </div>
              <ul className="promo-code">
                <li>
                  <span className="d-block text-3 fw-600">FLTOFFER</span>Up to
                  $500 Off on your booking. Hurry! Limited period offer.{" "}
                  <a className="text-1" href="#">
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <span className="d-block text-3 fw-600">HOTOFFER</span>Up to
                  $500 Off on your booking. Hurry! Limited period offer.{" "}
                  <a className="text-1" href="#">
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <span className="d-block text-3 fw-600">SUMMEROFFER</span>Up
                  to $500 Off on your booking. Hurry! Limited period offer.{" "}
                  <a className="text-1" href="#">
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <span className="d-block text-3 fw-600">BIGOFFER</span>Up to
                  $500 Off on your booking. Hurry! Limited period offer.{" "}
                  <a className="text-1" href="#">
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <span className="d-block text-3 fw-600">FLTOFFER</span>Up to
                  $500 Off on your booking. Hurry! Limited period offer.{" "}
                  <a className="text-1" href="#">
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <span className="d-block text-3 fw-600">FLTOFFER</span>Up to
                  $500 Off on your booking. Hurry! Limited period offer.{" "}
                  <a className="text-1" href="#">
                    Terms & Conditions
                  </a>
                </li>
              </ul> */}
              <div className="d-grid mt-3">
                <button
                  className="btn btn-primary"
                  onclick="location.href='payment.html';"
                  type="button"
                  onClick={()=>{proceedToPayment()}}
                >
                  Proceed To Payment
                </button>
              </div>
            </div>
          </aside>
          {/* <!-- Side Panel end -->  */}
        </div>
      </section>
    </div>
  );
}


const layoutSeats = ["17S","1C1S","1C2S","1C3S","1C4S","18S","1C5S","1C6S","1C7S","1C2B","1C8S","19S","20S","2C3B","2C4B","2C5B","53B","2C6B","2C7B","21S","2C8B","22S","3C9B","3C10B","3C11B","23S","3C12B","3C13B","3C14B","54B","24S","4C15B","4C16B","4C17B","25S","4C18B","4C19B","4C20B","26S","55B","5C21B","5C22B","5C23B","27S","5C24B","5C25B","5C26B","28S","29S","6C27B","6C28B","6C29B","56B","6C30B","6C31B","6C32B","30S","31S","7C33B","7C34B","7C35B","32S","7C36B","7C37B","7C38B","57B","33S","34S","8C39B","8C40B","8C41B","35S","8C42B","8C43B","8C44B","58B","36S","37S","9C45B","9C46B","9C47B","38S","9C48B","9C49B","9C50B","59B","10C9S","10C10S","10C11S","10C12S","10C51B","39S","10C13S","10C14S","10C15S","10C16S"]
