import React, { useEffect, useState } from "react";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import DatePicker from "react-datepicker";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import "./../commonComponents/select-search.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-datepicker/dist/react-datepicker.css";
import "./customStyle.css";
import $ from "jquery";
import {
  getTrainsList,
  getAllCities,
  getTrainStartingCities,
  getTrainEndingCities,
} from "../../actions/trains.actions";
import { toast } from "react-toastify";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

const CarouselOptions = {
  loop: true,
  margin: 10,
  items: 1,
  autoplay: true,
};

export default function Home() {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    st_city: 0,
    ending_city: 0,
    train_id: 0,
    date: new Date(),
  });
  const [trains, setTrains] = useState([]);
  const [startingCities, setStartingCities] = useState([]);
  const [endingCities, setEndingCities] = useState([]);

  useEffect(() => {
    fetchTrainsList();
    // fetchAllCities()
  }, []);

  const handleFormChange = (change) => {
    if (change.name === "train_id") {
      fetchStartingCities(change.value);
    }

    if (change.name === "st_city") {
      if (form.train_id !== 0) {
        fetchEndingCiites(change.value);
      }
    }

    setForm((prevValue) => {
      return {
        ...prevValue,
        [change.name]: change.value,
      };
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (form.train_id === 0 || form.st_city === 0 || form.ending_city === 0) {
      toast.error("Please select all fields in the form");
      return;
    }
    let date = dayjs(form.date).format('YYYY-MM-DD')
    // var newdate =
    //   date.getFullYear() +
    //   "-" +
    //   (date.getMonth() + 1) +
    //   "-" +
    //   (date.getDate() < 10 ? `0${date.getDate()}` : date.getDate());
    navigate("/toursList", { state: { ...form, date } });
    console.log("the form is ", form, date);
  };

  async function fetchTrainsList() {
    let resp = await getTrainsList();
    if (resp.status == 200) {
      let trainsObject = resp.data.trains.map((tr) => {
        return { name: tr.name, value: tr.id };
      });
      setTrains(trainsObject);
    } else {
      toast.error("Failed to fetch trains");
    }
  }

  // async function fetchAllCities(){
  //   let resp = await getAllCities();
  //   if(resp.status == 200){
  //     let citiesObject = resp.data.map(ct=> {return {name:ct.name,value:ct.id}})
  //     setStartingCities(citiesObject)
  //     setEndingCities(citiesObject)
  //   }else{
  //     toast.error("Failed to fetch all cities")
  //   }
  // }

  async function fetchStartingCities(train_id) {
    let resp = await getTrainStartingCities(train_id);
    if (resp.status == 200) {
      let citiesObject = resp.data.map((ct) => {
        return { name: ct.name, value: ct.id };
      });
      setStartingCities(citiesObject);
    } else {
      toast.error("Failed to fetch train starting cities");
    }
  }

  async function fetchEndingCiites(st_city) {
    let resp = await getTrainEndingCities(form.train_id, st_city);
    if (resp.status == 200) {
      console.log("here ending cities are ", resp);
      let citiesObject = resp.data.map((ct) => {
        return { name: ct.name, value: ct.id };
      });
      setEndingCities(citiesObject);
    } else {
      // toast.error("Failed to fetch train starting cities");
    }
  }

  return (
    //   <!-- Content
    // ============================================= -->
    <div id="content">
      <section className="container">
        <div className="bg-white shadow-md rounded p-4">
          <div className="row g-4">
            <div className="col-lg-5 col-xxl-6">
              <h2 className="text-4 mb-3">Book Train Tickets</h2>
              <p>Train Selection is Required</p>
              <form id="bookingTrain" onSubmit={handleFormSubmit}>
                <div className="row g-3">
                  <div className="col-lg-12">
                    <div>
                      {/* <div className="App"> */}

                      <div style={{ margin: "0 auto" }} className="">
                        <SelectSearch
                          options={trains}
                          value={form.train_id}
                          onChange={(e) => {
                            handleFormChange({ name: "train_id", value: e });
                          }}
                          search
                          filterOptions={fuzzySearch}
                          placeholder="Select Train"
                        />
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div>
                      {/* <div className="App"> */}

                      <div style={{ margin: "0 auto" }} className="">
                        <SelectSearch
                          options={startingCities}
                          value={form.st_city}
                          onChange={(e) => {
                            handleFormChange({ name: "st_city", value: e });
                          }}
                          search
                          name="st_city"
                          filterOptions={fuzzySearch}
                          placeholder="From"
                        />
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="position-relative">
                      <div style={{ margin: "0 auto" }} className="">
                        <SelectSearch
                          options={endingCities}
                          value={form.ending_city}
                          onChange={(e) => {
                            handleFormChange({ name: "ending_city", value: e });
                          }}
                          search
                          filterOptions={fuzzySearch}
                          placeholder="To"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="position-relative">
                      
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        label="Date"
                        inputFormat="MM/DD/YYYY"
                        dateAdapter={AdapterDayjs}
                        value={form.date}
                        onChange={(date) =>{
                          // console.log("from mobile is",e)
                          handleFormChange({ value: date, name: "date" })
                        }
                        }
                        renderInput={(params) => <TextField {...params} style={{ width: "100%" }}/>}
                        style={{ width: "100%" }}
                      />
                      </LocalizationProvider>
                      
                    </div>
                  </div>
                  {/* <div className="col-12">
                    <div className="travellers-className">
                      <input
                        type="text"
                        id="trainTravellersClass"
                        className="travellers-className-input form-control"
                        name="train-travellers-className"
                        placeholder="Travellers, className"
                        readOnly
                        required
                        onKeyPress="return false;"
                      ></input>
                      <span className="icon-inside">
                        <i className="fas fa-caret-down"></i>
                      </span>
                      <div className="travellers-dropdown">
                        <div className="row align-items-center">
                          <div className="col-sm-7 col-lg-8">
                            <p className="mb-sm-0">
                              Adults <small className="text-muted">(12+ yrs)</small>
                            </p>
                          </div>
                          <div className="col-sm-5 col-lg-4">
                            <div className="qty input-group">
                              <div className="input-group-prepend">
                                <button
                                  type="button"
                                  className="btn bg-light-4"
                                  data-value="decrease"
                                  data-target="#trainAdult-travellers"
                                  data-toggle="spinner"
                                >
                                  -
                                </button>
                              </div>
                              <input
                                type="text"
                                data-ride="spinner"
                                id="trainAdult-travellers"
                                className="qty-spinner form-control"
                                value="1"
                                readOnly
                              ></input>
                              <div className="input-group-append">
                                <button
                                  type="button"
                                  className="btn bg-light-4"
                                  data-value="increase"
                                  data-target="#trainAdult-travellers"
                                  data-toggle="spinner"
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="my-2" />
                        <div className="row align-items-center">
                          <div className="col-sm-7 col-lg-8">
                            <p className="mb-sm-0">
                              Children{" "}
                              <small className="text-muted">(2-12 yrs)</small>
                            </p>
                          </div>
                          <div className="col-sm-5 col-lg-4">
                            <div className="qty input-group">
                              <div className="input-group-prepend">
                                <button
                                  type="button"
                                  className="btn bg-light-4"
                                  data-value="decrease"
                                  data-target="#trainChildren-travellers"
                                  data-toggle="spinner"
                                >
                                  -
                                </button>
                              </div>
                              <input
                                type="text"
                                data-ride="spinner"
                                id="trainChildren-travellers"
                                className="qty-spinner form-control"
                                value="0"
                                readOnly
                              ></input>
                              <div className="input-group-append">
                                <button
                                  type="button"
                                  className="btn bg-light-4"
                                  data-value="increase"
                                  data-target="#trainChildren-travellers"
                                  data-toggle="spinner"
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="my-2" />
                        <div className="row align-items-center">
                          <div className="col-sm-7 col-lg-8">
                            <p className="mb-sm-0">
                              Infants{" "}
                              <small className="text-muted">(Below 2 yrs)</small>
                            </p>
                          </div>
                          <div className="col-sm-5 col-lg-4">
                            <div className="qty input-group">
                              <div className="input-group-prepend">
                                <button
                                  type="button"
                                  className="btn bg-light-4"
                                  data-value="decrease"
                                  data-target="#trainInfants-travellers"
                                  data-toggle="spinner"
                                >
                                  -
                                </button>
                              </div>
                              <input
                                type="text"
                                data-ride="spinner"
                                id="trainInfants-travellers"
                                className="qty-spinner form-control"
                                value="0"
                                readOnly
                              ></input>
                              <div className="input-group-append">
                                <button
                                  type="button"
                                  className="btn bg-light-4"
                                  data-value="increase"
                                  data-target="#trainInfants-travellers"
                                  data-toggle="spinner"
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-group my-3">
                          <select
                            id="train-className"
                            name="train-className"
                            className="form-select"
                          >
                            <option value="0">All className</option>
                            <option value="1">First className</option>
                            <option value="2">Second className</option>
                            <option value="3">First className Sleeper (SL)</option>
                            <option value="4">Second className Sleeper (SL)</option>
                            <option value="5">Business</option>
                          </select>
                        </div>
                        <div className="d-grid mt-3">
                          <button
                            className="btn btn-primary submit-done"
                            type="button"
                          >
                            Done
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-12 d-grid">
                    <button className="btn btn-primary" type="submit">
                      Search Trains
                    </button>
                  </div>
                </div>
              </form>
            </div>
            {/* <!-- Slideshow
          ============================================= --> */}
            <div className="col-lg-7 col-xxl-6">
              <OwlCarousel
                className=" owl-theme"
                animateOut={"fadeOut"}
                {...CarouselOptions}
                nav
              >
                <div className="item">
                  <a href="#">
                    <img
                      className="img-fluid"
                      src="images/slider/booking-banner-3.jpg"
                      alt="banner 3"
                    />
                  </a>
                </div>
                <div className="item">
                  <a href="#">
                    <img
                      className="img-fluid"
                      src="images/slider/booking_banner.png"
                      alt="banner 3"
                    />
                  </a>
                </div>
              </OwlCarousel>

              {/* <div
                className="owl-carousel owl-theme single-slider"
                data-animateout="fadeOut"
                data-animatein="fadeIn"
                data-autoplay="true"
                data-loop="true"
                data-autoheight="true"
                data-nav="true"
                data-items="1"
              >
                <div className="item">
                  <a href="#">
                    <img
                      className="img-fluid"
                      src="images/slider/booking-banner-3.jpg"
                      alt="banner 3"
                    />
                  </a>
                </div>
                <div className="item">
                  <a href="#">
                    <img
                      className="img-fluid"
                      src="images/slider/booking-banner-2.jpg"
                      alt="banner 2"
                    />
                  </a>
                </div>
              </div> */}
            </div>
            {/* <!-- Slideshow end -->  */}
          </div>
        </div>
      </section>
      <div className="container">
        <section className="section px-3 px-md-5">
          <h2 className="text-9 fw-600 text-center">
            Why Book Trains with Train Travels
          </h2>
          <p className="lead mb-5 text-center">
            Book Train Tickets Online. Save Time and Money!
          </p>
          <div className="row g-5">
            <div className="col-md-6">
              <div className="featured-box style-3">
                <div className="featured-box-icon bg-primary text-light rounded-circle">
                  {" "}
                  <i className="fas fa-percentage"></i>{" "}
                </div>
                <h3>Cheapest Price</h3>
                <p>
                  Always get cheapest price with the best in the industry. So
                  you get the best deal every time!
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="featured-box style-3">
                <div className="featured-box-icon bg-primary text-light rounded-circle">
                  {" "}
                  <i className="fas fa-times"></i>{" "}
                </div>
                <h3>Easy Cancellation & Refunds</h3>
                <p>Get instant refund and get any booking fees waived off!</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="featured-box style-3">
                <div className="featured-box-icon bg-primary text-light rounded-circle">
                  {" "}
                  <i className="fas fa-dollar-sign"></i>{" "}
                </div>
                <h3>No Booking Charges</h3>
                <p>
                  No hidden charges, no payment fees, and free customer service.
                  So you get the best deal every time!
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="featured-box style-3">
                <div className="featured-box-icon bg-primary text-light rounded-circle">
                  {" "}
                  <i className="fas fa-heart"></i>{" "}
                </div>
                <h3>Every time, anywhere</h3>
                <p>
                  Because your trip doesn't end with a ticket, we’re here for
                  you all the way
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="container">
        <div className="bg-white shadow-md rounded p-4">
          <div className="row gy-4 gx-5">
            <div className="col-md-6">
              <h3 className="text-6 mb-4">Top Train Routes</h3>
              <p className="d-flex align-items-center">
                Lahore to Faisalabad{" "}
                <a
                  href="#"
                  className="btn btn-sm btn-outline-primary shadow-none ms-auto"
                >
                  <i className="fas fa-search d-block d-sm-none"></i>{" "}
                  <span className="d-none d-sm-block">Find Tickets</span>
                </a>
              </p>
              <hr />
              <p className="d-flex align-items-center">
                Faisalabad to Lahore
                <a
                  href="#"
                  className="btn btn-sm btn-outline-primary shadow-none ms-auto"
                >
                  <i className="fas fa-search d-block d-sm-none"></i>{" "}
                  <span className="d-none d-sm-block">Find Tickets</span>
                </a>
              </p>
              <hr />
              <p className="d-flex align-items-center">
                Rawalpindi to Multan{" "}
                <a
                  href="#"
                  className="btn btn-sm btn-outline-primary shadow-none ms-auto"
                >
                  <i className="fas fa-search d-block d-sm-none"></i>{" "}
                  <span className="d-none d-sm-block">Find Tickets</span>
                </a>
              </p>
              <hr />
              <p className="d-flex align-items-center">
                Multan to Rawalpindi{" "}
                <a
                  href="#"
                  className="btn btn-sm btn-outline-primary shadow-none ms-auto"
                >
                  <i className="fas fa-search d-block d-sm-none"></i>{" "}
                  <span className="d-none d-sm-block">Find Tickets</span>
                </a>
              </p>
              <hr />
              <p className="d-flex align-items-center">
                Rawalpindi to Layyah{" "}
                <a
                  href="#"
                  className="btn btn-sm btn-outline-primary shadow-none ms-auto"
                >
                  <i className="fas fa-search d-block d-sm-none"></i>{" "}
                  <span className="d-none d-sm-block">Find Tickets</span>
                </a>
              </p>
              <hr />
              <p className="text-center mb-0">
                <a href="#" className="d-inline-block">
                  View All Top Routes
                </a>
              </p>
            </div>
            <div className="col-md-6">
              <h3 className="text-6 mb-4">What is Train Travels?</h3>
              <div className="accordion" id="accordionDefault">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Why choose Us
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionDefault"
                  >
                    <div className="accordion-body">
                      <p>
                        Train Travels (TM) is a TradeMark of{" "}
                        <strong>Imran Enterprises</strong> providing best in
                        className Online Train Ticket Booking services to users
                        across multiple cities in Pakistan.
                      </p>
                      <p className="mb-0">
                        Book Tickets from Famous Routes and Trains including
                        Ghouri Express and Thal Express and look forward for us
                        to engage more trains very soon.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Our Mission
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionDefault"
                  >
                    <div className="accordion-body">
                      {" "}
                      To provide with the ease of train bookings at home and
                      best in className travel experience to our customers.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Our Vision
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionDefault"
                  >
                    <div className="accordion-body">
                      {" "}
                      Our Vision is to deliver excellent quality service to our
                      clients. Providing them unforgettable, enjoyable travel,
                      with guaranteed services that exceed their expectations
                      With this objective, we aim to earn the reputation as
                      "Your Preferred Travel Partners"..{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section pb-0">
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-lg-6 text-center">
              {" "}
              <img
                style={{ borderRadius: "50px" }}
                className="img-fluid"
                alt=""
                src="images/mobile-app.png"
              />{" "}
            </div>
            <div className="col-md-7 col-lg-6">
              <h2 className="text-9 fw-600 my-4">
                Download Our Train Travels
                <br className="d-none d-lg-inline-block" />
                Mobile App Now
              </h2>
              <p className="lead">
                Download our app for the fastest, most convenient way to book
                train tickets online.
              </p>
              <p>
                With the ease of mobile app , you can avail the following
                services at the ease of your home.
              </p>
              <ul>
                <li>Ticket Booking</li>
                <li>Seat Reservation</li>
                <li>Route Fares</li>
                <li>and much more.....</li>
              </ul>
              <div className="d-flex flex-wrap pt-2">
                {" "}
                <a className="me-4" href="#">
                  <img alt="" src="images/app-store.png" />
                </a>
                <a href="#">
                  <img alt="" src="images/google-play-store.png" />
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
