import React from 'react'
import { Link } from "react-router-dom";

export default function Faq() {
  return (
    <div id="content">
    <div class="container">
      <div class="bg-white shadow-md rounded p-4">
        <h3 class="text-6 mb-4">Get answers to your queries</h3>
        <hr class="mx-n4 mb-4"/>
        
        <hr/>
        <div class="row">
          <div class="col-md-3">
            <h3 class="text-5 fw-400">Booking</h3>
          </div>
          <div class="col-md-9">
            <div class="accordion accordion-flush" id="accordionBooking">
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading5">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq5" aria-expanded="false" aria-controls="faq5">How can i cancel my booking?</button>
                </h2>
                <div id="faq5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionBooking">
                  <div class="accordion-body"> Before 48 hours of train departure time , you can lodge a cancellation request to us and the cancellation will be totally free of cost.. </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading6">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq6" aria-expanded="false" aria-controls="faq6">How do I print my e-ticket? </button>
                </h2>
                <div id="faq6" class="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionBooking">
                  <div class="accordion-body"> You can just print your e-ticket yourself or get the ticket id from your email invoice and get it printed from our booking counter at your departure station..</div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading7">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq7" aria-controls="faq7">Do I need an ID Proof while booking a train ticket?</button>
                </h2>
                <div id="faq7" class="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordionBooking">
                  <div class="accordion-body"> Yes , In order to get the ticket printed , you need the original cnic through which the ticket was booked along with you while travelling with us. </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading8">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq8" aria-expanded="false" aria-controls="faq8"> Why are seats priced differently on the same train?</button>
                </h2>
                <div id="faq8" class="accordion-collapse collapse" aria-labelledby="heading8" data-bs-parent="#accordionBooking">
                  <div class="accordion-body"> Seat and berth prices vary according to class and train. </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr/>
        <div class="row">
          <div class="col-md-3">
            <h3 class="text-5 fw-400">Payments</h3>
          </div>
          <div class="col-md-9">
            <div class="accordion accordion-flush" id="accordionPayments">
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading9">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq9" aria-expanded="false" aria-controls="faq9">How do I pay?</button>
                </h2>
                <div id="faq9" class="accordion-collapse collapse" aria-labelledby="heading9" data-bs-parent="#accordionPayments">
                  <div class="accordion-body"> We provide you multiple options to pay your ticket like mastercard , visa and mobile wallets like easypaisa. </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading10">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq10" aria-expanded="false" aria-controls="faq10">How do I pay using a credit/debit card?</button>
                </h2>
                <div id="faq10" class="accordion-collapse collapse" aria-labelledby="heading10" data-bs-parent="#accordionPayments">
                  <div class="accordion-body"> Just pay using leading payment gateway integrated on our website with secure payments. </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading11">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq11" aria-expanded="false" aria-controls="faq11">Can I use my bank's Internet Banking feature to make a payment?</button>
                </h2>
                <div id="faq11" class="accordion-collapse collapse" aria-labelledby="heading11" data-bs-parent="#accordionPayments">
                  <div class="accordion-body"> Sorry , but we're not currently supporting this feature. </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading12">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq12" aria-expanded="false" aria-controls="faq12">Are there any hidden charges (Octroi or Tax)? </button>
                </h2>
                <div id="faq12" class="accordion-collapse collapse" aria-labelledby="heading12" data-bs-parent="#accordionPayments">
                  <div class="accordion-body"> Currently , we're not charging any service fee or tax for this service. </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr/>
        <div class="row">
          <div class="col-md-3">
            <h3 class="text-5 fw-400">My Account</h3>
          </div>
          <div class="col-md-9">
            <div class="accordion accordion-flush" id="accordionAccount">
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading13">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq13" aria-expanded="false" aria-controls="faq13">Is there any registration fee?</button>
                </h2>
                <div id="faq13" class="accordion-collapse collapse" aria-labelledby="heading13" data-bs-parent="#accordionAccount">
                  <div class="accordion-body"> No , Accout creation and services are totally free of cost. </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading14">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq14" aria-expanded="false" aria-controls="faq14">Is my account information safe?</button>
                </h2>
                <div id="faq14" class="accordion-collapse collapse" aria-labelledby="heading14" data-bs-parent="#accordionAccount">
                  <div class="accordion-body"> At Train Travels , we have a straight customer oriented policy and we do not store any of users personal information. </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading15">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq15" aria-expanded="false" aria-controls="faq15">How does it work?</button>
                </h2>
                <div id="faq15" class="accordion-collapse collapse" aria-labelledby="heading15" data-bs-parent="#accordionAccount">
                  <div class="accordion-body"> Simply , create an account , verify your phone and you're good to go. </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading16">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq16" aria-expanded="false" aria-controls="faq16">I did not receive the cashback</button>
                </h2>
                <div id="faq16" class="accordion-collapse collapse" aria-labelledby="heading16" data-bs-parent="#accordionAccount">
                  <div class="accordion-body"> Cash Backs are returned on a bi-weekly basis and we assure your cashback , please have patience. </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading17">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq17" aria-expanded="false" aria-controls="faq17">Forgot my password! What next?</button>
                </h2>
                <div id="faq17" class="accordion-collapse collapse" aria-labelledby="heading17" data-bs-parent="#accordionAccount">
                  <div class="accordion-body"> Just click on forgot password and click on the link recived in the email. </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <hr/>
        <div class="text-center my-3 my-md-5">
          <p class="text-4 mb-3">Can't find what you're looking for? Our customer care team are here to help</p>
          <Link to="/support" className="btn btn-primary">Contact Customer Care</Link> </div>
      </div>
    </div>
  </div>
  )
}
