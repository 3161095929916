import axiosInstance from '../utils/axios';

class Train {

    static getVacantSeats = (form) => {

        let apiPath = '/api/tickets/vacant';
        return axiosInstance.post(apiPath, {
            ...form
        });

    };

    static getTicketFare = (form) => {

        let apiPath = '/api/tickets/fare';
        return axiosInstance.post(apiPath, {
            ...form
        });

    };

    static createOneTicket = (form) => {

        let apiPath = '/api/tickets/online';
        return axiosInstance.post(apiPath, {
            ...form
        });

    };

    // static getTrainStartingCities = (train_id) =>{
    //     let apiPath = `/api/trainCities/starting/${train_id}`;
    //     return axiosInstance.get(apiPath );
    // }

    // static getTrainEndingCities =  (train_id , st_city) => {

    //     let apiPath = `/api/trainCities/ending/${train_id}/${st_city}`;
    //     return axiosInstance.get(apiPath);

    // };

    // static getAllCities =  () => {

    //     let apiPath = `api/trainCities/all`;
    //     return axiosInstance.get(apiPath);

    // };

    // static getTrainCoaches = (form) => {
    //     let apiPath = `/api/tourCoaches/trainCoaches`;
    //     return axiosInstance.post(apiPath , {
    //         ...form
    //     } );
    // }





}
export default Train;