import React from "react";

export default function ChangePassword() {
  return (
    <>
      {/* // <!-- Page Header
    // ============================================= --> */}
      <section class="page-header page-header-dark bg-secondary">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-8">
              <h1>My Profile</h1>
            </div>
            <div class="col-md-4">
              <ul class="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li>
                  <a href="index-2.html">Home</a>
                </li>
                <li class="active">My Profile</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* //   <!-- Page Header end -->  */}

      {/* //   <!-- Content
//   ============================================= --> */}
      <div id="content">
        <div class="container">
          <div class="row">
            <div class="col-lg-3">
              {/* <!-- Nav Link
          ============================================= --> */}
              <ul class="nav nav-pills alternate flex-lg-column mb-3 mb-lg-0 sticky-top">
                <li class="nav-item">
                  <a class="nav-link" href="profile.html">
                    <span class="me-2">
                      <i class="fas fa-user"></i>
                    </span>
                    Personal Information
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="profile-favourites.html">
                    <span class="me-2">
                      <i class="fas fa-bookmark"></i>
                    </span>
                    Favourites
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="profile-orders-history.html">
                    <span class="me-2">
                      <i class="fas fa-history"></i>
                    </span>
                    Orders History
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="profile-cards.html">
                    <span class="me-2">
                      <i class="fas fa-credit-card"></i>
                    </span>
                    Credit or Debit Cards
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link active" href="profile-password.html">
                    <span class="me-2">
                      <i class="fas fa-key"></i>
                    </span>
                    Change Password
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="profile-notifications.html">
                    <span class="me-2">
                      <i class="fas fa-bell"></i>
                    </span>
                    Notifications
                  </a>
                </li>
              </ul>
              {/* <!-- Nav Link end -->  */}
            </div>
            <div class="col-lg-9">
              <div class="bg-white shadow-md rounded p-4">
                {/* <!-- Change Password
          ============================================= --> */}
                <h4 class="mb-4">Change Password</h4>
                <hr class="mx-n4 mb-4" />
                <div class="row g-4">
                  <div class="col-lg-8">
                    <form id="changePassword" method="post">
                      <div class="mb-3">
                        <label class="form-label" for="existingPassword">
                          Existing Password
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          data-bv-field="existingpassword"
                          id="existingPassword"
                          required
                          placeholder="Existing Password"
                        />
                      </div>
                      <div class="mb-3">
                        <label class="form-label" for="newPassword">
                          New Password
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          data-bv-field="newpassword"
                          id="newPassword"
                          required
                          placeholder="New Password"
                        />
                      </div>
                      <div class="mb-3">
                        <label class="form-label" for="existingPassword">
                          Confirm Password
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          data-bv-field="confirmgpassword"
                          id="confirmPassword"
                          required
                          placeholder="Confirm Password"
                        />
                      </div>
                      <button class="btn btn-primary" type="submit">
                        Update Password
                      </button>
                    </form>
                  </div>
                  <div class="col-lg-4">
                    {/* <!-- Privacy Information
			============================================= --> */}
                    <div class="bg-light-2 rounded p-4">
                      <h3 class="text-4 mb-2">We value your Privacy</h3>
                      <p class="mb-0">
                        We will not sell or distribute your contact information.
                        Read our <a href="#">Privacy Policy</a>.
                      </p>
                      <hr class="mx-n4" />
                      <h3 class="text-4 mb-3">Billing Enquiries</h3>
                      <p class="mb-0">
                        Do not hesitate to reach our{" "}
                        <a href="#">support team</a> if you have any queries.
                      </p>
                    </div>
                    {/* <!-- Privacy Information end -->  */}
                  </div>
                </div>
                {/* <!-- Change Password end -->  */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
