import React from 'react'

export default function Profile() {
  return (
    <div id="content">
    <div class="container">
      <div class="row">
        <div class="col-lg-3"> 
          {/* <!-- Nav Link
          ============================================= --> */}
          <ul class="nav nav-pills alternate flex-lg-column mb-3 mb-lg-0 sticky-top">
            <li class="nav-item"><a class="nav-link active" href="profile.html"><span class="me-2"><i class="fas fa-user"></i></span>Personal Information</a></li>
            <li class="nav-item"><a class="nav-link" href="profile-favourites.html"><span class="me-2"><i class="fas fa-bookmark"></i></span>Favourites</a></li>
            <li class="nav-item"><a class="nav-link" href="profile-orders-history.html"><span class="me-2"><i class="fas fa-history"></i></span>Orders History</a></li>
            <li class="nav-item"><a class="nav-link" href="profile-cards.html"><span class="me-2"><i class="fas fa-credit-card"></i></span>Credit or Debit Cards</a></li>
            <li class="nav-item"><a class="nav-link" href="profile-password.html"><span class="me-2"><i class="fas fa-key"></i></span>Change Password</a></li>
            <li class="nav-item"><a class="nav-link" href="profile-notifications.html"><span class="me-2"><i class="fas fa-bell"></i></span>Notifications</a></li>
          </ul>
          {/* <!-- Nav Link end -->  */}
        </div>
        <div class="col-lg-9">
          <div class="bg-white shadow-md rounded p-4"> 
            {/* <!-- Personal Information
          ============================================= --> */}
            <h4 class="mb-4">Personal Information</h4>
            <hr class="mx-n4 mb-4"/>
            <div class="row">
              <div class="col-lg-8">
                <form id="personalInformation" method="post">
                  <div class="mb-3">
                    <div class="form-check form-check-inline">
                      <input id="male" name="profile" class="form-check-input" checked="" required type="radio"/>
                      <label class="form-check-label" for="male">Male</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input id="female" name="profile" class="form-check-input" required type="radio"/>
                      <label class="form-check-label" for="female">Female</label>
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label" for="fullName">Full Name</label>
                    <input type="text" value="Johne Cary" class="form-control" data-bv-field="fullName" id="fullName" required placeholder="Full Name"/>
                  </div>
                  <div class="mb-3">
                    <label class="form-label" for="mobileNumber">Mobile Number</label>
                    <input type="text" value="9898989898" class="form-control" data-bv-field="mobilenumber" id="mobileNumber" required placeholder="Mobile Number"/>
                  </div>
                  <div class="mb-3">
                    <label class="form-label" for="emailID">Email ID</label>
                    <input type="text" value="jhonecary2018@gmail.com" class="form-control" data-bv-field="emailid" id="emailID" required placeholder="Email ID"/>
                  </div>
                  <div class="mb-3">
                    <label class="form-label" for="birthDate">Date of Birth</label>
                    <input id="birthDate" value="06-09-2002" type="text" class="form-control" required placeholder="Date of Birth"/>
                  </div>
                  <div class="mb-3">
                    <label class="form-label" for="inputCountry">Country</label>
                    <select class="form-select" id="inputCountry" name="country_id">
                      <option value=""> --- Please Select --- </option>
                      <option value="244">Aaland Islands</option>
                      <option value="1">Afghanistan</option>
                      <option value="2">Albania</option>
                      <option value="3">Algeria</option>
                      <option value="4">American Samoa</option>
                      <option value="5">Andorra</option>
                      <option value="6">Angola</option>
                      <option value="7">Anguilla</option>
                      <option value="8">Antarctica</option>
                      <option value="9">Antigua and Barbuda</option>
                      <option value="10">Argentina</option>
                      <option value="11">Armenia</option>
                      <option value="12">Aruba</option>
                      <option value="252">Ascension Island (British)</option>
                      <option value="13">Australia</option>
                      <option value="14">Austria</option>
                      <option value="15">Azerbaijan</option>
                      <option value="16">Bahamas</option>
                      <option value="17">Bahrain</option>
                      <option value="18">Bangladesh</option>
                      <option value="19">Barbados</option>
                      <option value="20">Belarus</option>
                      <option value="21">Belgium</option>
                      <option value="22">Belize</option>
                      <option value="23">Benin</option>
                      <option value="24">Bermuda</option>
                      <option value="25">Bhutan</option>
                      <option value="26">Bolivia</option>
                      <option value="245">Bonaire, Sint Eustatius and Saba</option>
                      <option value="27">Bosnia and Herzegovina</option>
                      <option value="28">Botswana</option>
                      <option value="29">Bouvet Island</option>
                      <option value="30">Brazil</option>
                      <option value="31">British Indian Ocean Territory</option>
                      <option value="32">Brunei Darussalam</option>
                      <option value="33">Bulgaria</option>
                      <option value="34">Burkina Faso</option>
                      
                    </select>
                  </div>
                  <button class="btn btn-primary" type="submit">Update Now</button>
                </form>
              </div>
              <div class="col-lg-4 mt-4 mt-lg-0 "> 
                {/* <!-- Privacy Information
			============================================= --> */}
                <div class="bg-light-2 rounded p-4">
                  <h3 class="text-4 mb-2">We value your Privacy</h3>
                  <p class="mb-0">We will not sell or distribute your contact information. Read our <a href="#">Privacy Policy</a>.</p>
                  <hr class="mx-n4"/>
                  <h3 class="text-4 mb-3">Billing Enquiries</h3>
                  <p class="mb-0">Do not hesitate to reach our <a href="#">support team</a> if you have any queries.</p>
                </div>
                {/* <!-- Privacy Information end -->  */}
              </div>
            </div>
            {/* <!-- Personal Information end -->  */}
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
