import axiosInstance from '../utils/axios';

class Customer {

    static sendOtp = (form) => {

        let apiPath = '/api/cust/otp';
        return axiosInstance.post(apiPath, {
            ...form
        });

    };

    static verifyOtp = (form) => {

        let apiPath = '/api/cust/verifyOtp';
        return axiosInstance.post(apiPath, {
            ...form
        });

    };

    



}
export default Customer;