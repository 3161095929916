import axiosInstance from '../utils/axios';

class City {
    
    static getPaymentObject = (form) => {
    
        let apiPath = '/api/trans/pay';
        return axiosInstance.post(apiPath , {
            ...form
        });
    
    };

    static postToJazzcash = (form) =>{
        let apiPath = 'https://jazzcash.com.pk/CustomerPortal/transactionmanagement/merchantform';
        return axiosInstance.post(apiPath , {
            ...form
        });
    }

    static helloTest =  () => {
    
        let apiPath = '/api/guest/hello';
        return axiosInstance.get(apiPath);
    
    };

    
    
}
export default City;