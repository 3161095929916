import React from 'react'
import { Link } from "react-router-dom";

export default function Header() {
  return (
//     <!-- Header
//   ============================================= -->
  <header id="header">
    <div className="container mb-3">
      <div className="header-row">
        <div className="header-column justify-content-start"> 
          
          {/* <!-- Logo
          ============================================= --> */}
          <div className="logo me-2 me-lg-3"> <Link to="/"><img src="images/logo.png" alt="Quickai" /></Link> </div>
          {/* <!-- Logo end -->  */}
          
        </div>
        <div className="header-column justify-content-end"> 
          
          {/* <!-- Primary Navigation
          ============================================= --> */}
          <nav className="primary-menu navbar navbar-expand-lg">
            <div id="header-nav" className="collapse navbar-collapse">
              <ul className="navbar-nav">
              <li><Link to="/">Home</Link></li>
              <li><Link to="/about">About Us</Link></li>
              <li><Link to="/ourpolicy">Our Policies</Link></li>
              <li><Link to="/contact">Contact Us</Link></li>
              <li><Link to="/support">Support</Link></li>
              <li><Link to="/faq">FAQ</Link></li>
               
               
                  <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="about-us.html">About Us</a></li>
                    <li className="dropdown"><a className="dropdown-item dropdown-toggle" href="#">Login/Signup</a>
                      <ul className="dropdown-menu">
                        <li><a className="dropdown-item" href="login.html">Login</a></li>
                        <li><a className="dropdown-item" href="signup.html">Sign Up</a></li>
                        <li><a className="dropdown-item" href="forgot-password.html">Forgot Password</a></li>
                        <li><a className="dropdown-item" href="otp.html">OTP - One Time Password</a></li>
                      </ul>
                    </li>
                    <li className="dropdown"><a className="dropdown-item dropdown-toggle" href="#">My Profile</a>
                      <ul className="dropdown-menu">
                        <li><a className="dropdown-item" href="profile.html">Personal Information</a></li>
                        <li><a className="dropdown-item" href="profile-favourites.html">Favourites</a></li>
                        <li><a className="dropdown-item" href="profile-cards.html">Credit or Debit Cards</a></li>
                        <li><a className="dropdown-item" href="profile-notifications.html">Notifications</a></li>
                        <li><a className="dropdown-item" href="profile-orders-history.html">Orders History</a></li>
                        <li><a className="dropdown-item" href="profile-password.html">Change Password</a></li>
                      </ul>
                    </li>
                    <li><a className="dropdown-item" href="payment.html">Payment</a></li>
                    <li><a className="dropdown-item" href="payment-2.html">Payment 2</a></li>
                    <li><a className="dropdown-item" href="help.html">Help</a></li>
                    <li><a className="dropdown-item" href="faq.html">Faq</a></li>
                    <li><a className="dropdown-item" href="support.html">Support</a></li>
                    <li><a className="dropdown-item" href="contact-us.html">Contact Us</a></li>
                    <li><a className="dropdown-item" href="404.html">404</a></li>
                    <li><a className="dropdown-item" href="coming-soon.html" target="_blank">Coming Soon</a></li>
                    <li><a className="dropdown-item" href="elements.html">Elements</a></li>
                    <li><a className="dropdown-item" href="elements-2.html">Elements 2</a></li>
                  </ul>
                
              </ul>
            </div>
          </nav>
          {/* <!-- Primary Navigation end -->  */}
          
          {/* <!-- Collapse Button
		  =============================== --> */}
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#header-nav"> <span></span> <span></span> <span></span> </button>
          <div className="vr mx-2 h-25 my-auto"></div>
          {/* <!-- Login Signup
		  =============================== --> */}
          {/* <nav className="login-signup navbar navbar-expand">
            <ul className="navbar-nav">
              <li className="profile"><a className="pe-0" data-bs-toggle="modal" data-bs-target="#login-modal" href="#" title="Login / Sign up"><span className="d-none d-sm-inline-block">Login / Sign up</span> <span className="user-icon ms-sm-2"><i className="fas fa-user"></i></span></a></li>
            </ul>
          </nav> */}
        </div>
      </div>
    </div>
  </header>
//   <!-- Header end --> 
  )
}
