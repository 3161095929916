import axiosInstance from '../utils/axios';

class Train {
    
    static getTrainsList = () => {
    
        let apiPath = '/api/trains';
        return axiosInstance.get(apiPath);
    
    };

    static getTrainStartingCities = (train_id) =>{
        let apiPath = `/api/trainCities/starting`;
        return axiosInstance.post(apiPath , {
            train_id
        } );
        
    }

    static getTrainEndingCities =  (train_id , st_city) => {
    
        let apiPath = `/api/trainCities/ending`;
        return axiosInstance.post(apiPath , {
            train_id , st_city
        } );
        
    
    };
    
    static getAllCities =  () => {
    
        let apiPath = `api/trainCities/all`;
        return axiosInstance.get(apiPath);
    
    };

    static getTrainCoaches = (form) => {
        let apiPath = `/api/tourCoaches/trainCoaches`;
        return axiosInstance.post(apiPath , {
            ...form
        } );
    }

    static getTrainServiceFee = (train_id) => {
        let apiPath = `/api/trains/prefs`;
        return axiosInstance.post(apiPath , {
            train_id
        } );
    }



    
    
}
export default Train;