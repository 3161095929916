import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Economy3 from "./Economy3";
import Economy2 from "./Economy2";
import EconomyGhouri from "./EconomyGhouri";
import AcStd98 from "./AcStd98/AcStd98";
import {toast} from "react-toastify"

export default function SelectSeat() {
  const [Layout , setLayout] = useState("");
  const [coach , setCoach] = useState({});
  const location = useLocation();
  const navigate = useNavigate();


  const allowedLayouts = ["EconomyGhouri", "Economy3", "Economy2", "AcStd98"];
  useEffect(() => {
    if (
      location.state &&
      location.state.coach_types &&
      location.state.coach_number !== 0
    ) {
      if (!allowedLayouts.includes(location.state.coach_types.layout)) {
        toast.error("Invalid Coach Type");
        navigate("/");
      }
      setCoach(location.state)
      setLayout(location.state.coach_types.layout)
    } else {
      toast.error("Invalid Routing");
      navigate("/");
    }
  }, [location]);

  useEffect(()=>{console.log("coach data is ", coach)},[coach])
  return (
    <>
      {Layout === "Economy2" && <Economy2 coachData={coach}/>}
      {Layout === "Economy3" && <Economy3 coachData={coach}/>}
      {Layout === "EconomyGhouri" && <EconomyGhouri coachData={coach}/>}
      {Layout === "AcStd98" && <AcStd98 coachData={coach}/>}
    </>
  );
}
