import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getTrainCoaches } from "../../actions/trains.actions";
import { v4 as uuidv4 } from "uuid";

export default function TourList() {
  const location = useLocation();
  const navigate = useNavigate();
  const [tour, setTour] = useState({ starting_city: "", ending_city: "" });
  const [coaches, setCoaches] = useState([]);

  const checkCoachAvailability = (coach) => {
    // alert(JSON.stringify(coach));
    navigate("/selectSeat", { state: { ...coach, ...location.state } });
  };

  async function fetchTrainCoachesByDate() {
    let dispatchObject = { ...location.state };
    let resp = await getTrainCoaches(dispatchObject);
    if (resp.status === 200) {
      let respData = resp.data;
      setTour(respData.tour);
      setCoaches(respData.coaches);
    } else {
      toast.error("No Route Found.");
      navigate("/");
    }
    $("#preloader").delay(333).fadeOut("slow");
  }

  useEffect(() => {
    if (
      location.state &&
      location.state.train_id &&
      location.state.train_id !== 0
    ) {
      fetchTrainCoachesByDate();
    } else {
      toast.error("No Route Found.");
      navigate("/");
    }
  }, [location]);

  useEffect(() => {
    console.log("coaches are", coaches);
  }, [coaches]);
  useEffect(() => {
    $("#preloader").delay(333).fadeIn("slow");
  }, []);

  return (
    <div id="content">
      <section className="container">
        {/* <form id="bookingTrain" method="post">
          <div className="row g-3 mb-4">
            <div className="col-md-6 col-lg">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  id="trainFrom"
                  required
                  placeholder="From"
                />
                <span className="icon-inside">
                  <i className="fas fa-map-marker-alt"></i>
                </span>{" "}
              </div>
            </div>
            <div className="col-md-6 col-lg">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  id="trainTo"
                  required
                  placeholder="To"
                />
                <span className="icon-inside">
                  <i className="fas fa-map-marker-alt"></i>
                </span>{" "}
              </div>
            </div>
            <div className="col-md-6 col-lg">
              <div className="position-relative">
                <input
                  id="trainDepart"
                  type="text"
                  className="form-control"
                  required
                  placeholder="Depart Date"
                />
                <span className="icon-inside">
                  <i className="far fa-calendar-alt"></i>
                </span>{" "}
              </div>
            </div>
            <div className="col-md-6 col-lg">
              <div className="travellers-className">
                <input
                  type="text"
                  id="trainTravellersclassName"
                  className="travellers-className-input form-control"
                  name="train-travellers-className"
                  placeholder="Travellers, className"
                  readOnly
                  required
                  // onKeyPress="return false;"
                />
                <span className="icon-inside">
                  <i className="fas fa-caret-down"></i>
                </span>
                <div className="travellers-dropdown">
                  <div className="row align-items-center">
                    <div className="col-sm-7">
                      <p className="mb-sm-0">
                        Adults <small className="text-muted">(12+ yrs)</small>
                      </p>
                    </div>
                    <div className="col-sm-5">
                      <div className="qty input-group">
                        <div className="input-group-prepend">
                          <button
                            type="button"
                            className="btn bg-light-4"
                            data-value="decrease"
                            data-target="#trainAdult-travellers"
                            data-toggle="spinner"
                          >
                            -
                          </button>
                        </div>
                        <input
                          type="text"
                          data-ride="spinner"
                          id="trainAdult-travellers"
                          className="qty-spinner form-control"
                          // value="1"
                          readOnly
                        />
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="btn bg-light-4"
                            data-value="increase"
                            data-target="#trainAdult-travellers"
                            data-toggle="spinner"
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="my-2" />
                  <div className="row align-items-center">
                    <div className="col-sm-7">
                      <p className="mb-sm-0">
                        Children{" "}
                        <small className="text-muted">(2-12 yrs)</small>
                      </p>
                    </div>
                    <div className="col-sm-5">
                      <div className="qty input-group">
                        <div className="input-group-prepend">
                          <button
                            type="button"
                            className="btn bg-light-4"
                            data-value="decrease"
                            data-target="#trainChildren-travellers"
                            data-toggle="spinner"
                          >
                            -
                          </button>
                        </div>
                        <input
                          type="text"
                          data-ride="spinner"
                          id="trainChildren-travellers"
                          className="qty-spinner form-control"
                          // value="0"
                          readOnly
                        />
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="btn bg-light-4"
                            data-value="increase"
                            data-target="#trainChildren-travellers"
                            data-toggle="spinner"
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="my-2" />
                  <div className="row align-items-center">
                    <div className="col-sm-7">
                      <p className="mb-sm-0">
                        Infants{" "}
                        <small className="text-muted">(Below 2 yrs)</small>
                      </p>
                    </div>
                    <div className="col-sm-5">
                      <div className="qty input-group">
                        <div className="input-group-prepend">
                          <button
                            type="button"
                            className="btn bg-light-4"
                            data-value="decrease"
                            data-target="#trainInfants-travellers"
                            data-toggle="spinner"
                          >
                            -
                          </button>
                        </div>
                        <input
                          type="text"
                          data-ride="spinner"
                          id="trainInfants-travellers"
                          className="qty-spinner form-control"
                          // value="0"
                          readOnly
                        />
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="btn bg-light-4"
                            data-value="increase"
                            data-target="#trainInfants-travellers"
                            data-toggle="spinner"
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group my-3">
                    <select
                      id="train-className"
                      name="train-className"
                      className="form-select"
                    >
                      <option value="0">All className</option>
                      <option value="1">First className</option>
                      <option value="2">Second className</option>
                      <option value="3">First className Sleeper (SL)</option>
                      <option value="4">Second className Sleeper (SL)</option>
                      <option value="5">Business</option>
                    </select>
                  </div>
                  <div className="d-grid">
                    <button
                      className="btn btn-primary submit-done"
                      type="button"
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-2 d-grid">
              <button className="btn btn-primary" type="submit">
                Search
              </button>
            </div>
          </div>
        </form> */}
        <div className="row">
          {/* <!-- Side Panel
        ============================================= --> */}
          {/* <aside className="col-md-3">
            <div className="bg-white shadow-md rounded p-3">
              <h3 className="text-5">Filter</h3>
              <hr className="mx-n3" />
              <div
                className="accordion accordion-flush style-2 mt-n3"
                id="toggleAlternate"
              >
                {/* <div className="accordion-item">
                  <h2 className="accordion-header" id="departure">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#toggleDeparture"
                      aria-expanded="true"
                      aria-controls="toggleDeparture"
                    >
                      Departure Time
                    </button>
                  </h2>
                  <div
                    id="toggleDeparture"
                    className="accordion-collapse collapse show"
                    aria-labelledby="departure"
                  >
                    <div className="accordion-body">
                      <p>
                        <span className="slider-time-departure">00:00</span> -{" "}
                        <span className="slider-time-departure">23:59</span>
                      </p>
                      <div id="slider-range-departure"></div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="arrival">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#toggleArrival"
                      aria-expanded="true"
                      aria-controls="toggleArrival"
                    >
                      Arrival Time
                    </button>
                  </h2>
                  <div
                    id="toggleArrival"
                    className="accordion-collapse collapse show"
                    aria-labelledby="arrival"
                  >
                    <div className="accordion-body">
                      <p>
                        <span className="slider-time-arrival">00:00</span> -{" "}
                        <span className="slider-time-arrival">23:59</span>
                      </p>
                      <div id="slider-range-arrival"></div>
                    </div>
                  </div>
                </div> */}
          {/* <div className="accordion-item">
                  <h2 className="accordion-header" id="fareclassName">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#togglefareclassName"
                      aria-expanded="true"
                      aria-controls="togglefareclassName"
                    >
                      Fare className
                    </button>
                  </h2>
                  <div
                    id="togglefareclassName"
                    className="accordion-collapse collapse show"
                    aria-labelledby="fareclassName"
                  >
                    <div className="accordion-body">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="1stclassName"
                          name="fareclassName"
                          className="form-check-input"
                        />
                        <label className="form-check-label" for="1stclassName">
                          First className
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="2ndclassName"
                          name="fareclassName"
                          className="form-check-input"
                        />
                        <label className="form-check-label" for="2ndclassName">
                          Second className
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="1stclassNameSL"
                          name="fareclassName"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          for="1stclassNameSL"
                        >
                          First className Sleeper (SL)
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="2ndclassNameSL"
                          name="fareclassName"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          for="2ndclassNameSL"
                        >
                          Second className Sleeper (SL)
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="business"
                          name="fareclassName"
                          className="form-check-input"
                        />
                        <label className="form-check-label" for="business">
                          Business
                        </label>
                      </div>
                    </div>
                  </div>
                </div> */}
          {/* </div>
            </div>
          </aside> */}
          {/* <!-- Side Panel end --> */}

          <div className="col-md-12 mt-4 mt-md-0">
            <div className="bg-white shadow-md rounded py-4">
              <div className="mx-3 mb-3 text-center">
                <h2 className="text-6 mb-4">
                  {`${tour.starting_city} `}
                  <small className="mx-2">to</small>
                  {` ${tour.ending_city}`}
                </h2>
              </div>
              <div className="text-1 bg-light-3 border border-end-0 border-start-0 py-2 px-3">
                <div className="row">
                  <div className="col col-sm-3">
                    <span className="d-none d-sm-block">Coach Name</span>
                  </div>
                  <div className="col col-sm-3 text-center">Departure</div>

                  <div className="col col-sm-3 text-center">Arrival</div>
                </div>
              </div>
              <div
                className="train-list"
                style={{ overflowY: "scroll", height: "38rem" }}
              >
                {coaches.map((coach) => {
                  return (
                    <div className="train-item" key={uuidv4()}>
                      <div className="row align-items-sm-center flex-row py-4 px-3">
                        <div className="col col-sm-3">
                          {" "}
                          {coach.coach_types.name.includes("AC") ? (
                            <span
                              className="text-5 train-name"
                              style={{ color: "#F09B1B" }}
                            >
                              {coach.coach_types.name}
                            </span>
                          ) : (
                            <span
                              className="text-4 train-name"
                              
                            >
                              {coach.coach_types.name}
                            </span>
                          )}
                          <span className="text-muted d-block">
                            {coach.coach_number}
                          </span>{" "}
                        </div>
                        <div className="col col-sm-3 text-center time-info">
                          {" "}
                          <span className="text-4 text-dark">
                            {tour.depart_time}
                          </span>{" "}
                          <small className="text-muted d-block">
                            {tour.starting_city} Jn
                          </small>{" "}
                        </div>

                        <div className="col col-sm-3 text-center time-info">
                          {" "}
                          <span className="text-4 text-dark">
                            {tour.arrival_time}
                          </span>{" "}
                          <small className="text-muted d-block">
                            {tour.ending_city} Jn
                          </small>{" "}
                        </div>
                        <div className="col-12 col-sm-3 text-center mt-2 mt-sm-0">
                          {" "}
                          <button
                            href="#"
                            className="btn btn-sm btn-outline-primary shadow-none"
                            data-bs-toggle="modal"
                            data-bs-target="#train-1"
                            onClick={() => checkCoachAvailability(coach)}
                          >
                            <i
                              className="fas fa-ellipsis-h d-none d-sm-block d-xl-none"
                              data-bs-toggle="tooltip"
                              title="Check Availability"
                            ></i>{" "}
                            <span className="d-block d-sm-none d-xl-block">
                              Check Availability
                            </span>
                          </button>{" "}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <ul className="pagination justify-content-center mt-4 mb-0">
                <li className="page-item disabled">
                  {" "}
                  <a className="page-link" href="#" tabIndex="-1">
                    <i className="fas fa-angle-left"></i>
                  </a>{" "}
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    1
                  </a>
                </li>
                <li className="page-item active">
                  {" "}
                  <a className="page-link" href="#">
                    2
                  </a>{" "}
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    3
                  </a>
                </li>
                <li className="page-item">
                  {" "}
                  <a className="page-link" href="#">
                    <i className="fas fa-angle-right"></i>
                  </a>{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
