import React from 'react'
import { Link } from "react-router-dom";

export default function Footer() {
  return (
//     <!-- Footer
//   ============================================= -->
  <footer id="footer">
    <section className="section bg-white shadow-md pt-4 pb-3">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-md-4">
            <div className="featured-box text-center">
              <div className="featured-box-icon"> <i className="fas fa-lock"></i> </div>
              <h4>100% Secure Payments</h4>
              <p>Moving your card details to a much more secured place.</p>
            </div>
          </div>
          <div className="col-sm-6 col-md-4">
            <div className="featured-box text-center">
              <div className="featured-box-icon"> <i className="fas fa-thumbs-up"></i> </div>
              <h4>Trust pay</h4>
              <p>100% Payment Protection. Easy Return Policy.</p>
            </div>
          </div>
          
          <div className="col-sm-6 col-md-4">
            <div className="featured-box text-center">
              <div className="featured-box-icon"> <i className="far fa-life-ring"></i> </div>
              <h4>24X7 Support</h4>
              <p>We're here to help. Have a query and need help ? <a href="#">Click here</a></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className="container mt-4">
      <div className="row g-4">
        <div className="col-md-4">
          <img src="images/tt/logo-footer.png" alt="" />
          <h2>Train Travels</h2>
        </div>
        <div className="col-md-4">
          <img src="images/logo-ie.jpg" alt="" />
          <h2>Imran Enterprises</h2>
        </div>
        <div className="col-md-4">
          <p>Payment</p>
          <ul className="payments-types">
            <li><a href="#" target="_blank"> <img data-bs-toggle="tooltip" src="images/payment/visa.png" alt="visa" title="Visa"></img></a></li>
            <li><a href="#" target="_blank"> <img data-bs-toggle="tooltip" src="images/payment/discover.png" alt="discover" title="Discover"></img></a></li>
            <li><a href="#" target="_blank"> <img data-bs-toggle="tooltip" src="images/payment/paypal.png" alt="paypal" title="PayPal"></img></a></li>
            <li><a href="#" target="_blank"> <img data-bs-toggle="tooltip" src="images/payment/american.png" alt="american express" title="American Express"></img></a></li>
            <li><a href="#" target="_blank"> <img data-bs-toggle="tooltip" src="images/payment/mastercard.png" alt="discover" title="Discover"></img></a></li>
          </ul>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="footer-copyright">
      <p className="copyright-text">Copyright © 2022 <Link to="/about" >Imran Enterprises</Link>. All Rights Reserved.</p>
        <p className="copyright-text">Powered By: <a href="#">Brown Developers</a>.</p>
      </div>
    </div>
  </footer>
 
  )
}
