import React , {useEffect , useState} from "react";
import {  useParams ,useLocation , useSearchParams } from 'react-router-dom';

export default function PaymentSuccess() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [successMessage , setSuccessMessage] = useState('')
  let locationParams = useLocation();
  useEffect(()=>{
    let paramData = searchParams.get("data");
    if(paramData && paramData.length > 0){
      setSuccessMessage(paramData)
    }
  },[searchParams])

  useEffect(()=>{
    console.log("location params are",locationParams);
  },[locationParams])
  return (
    <div id="content">
      <div class="container">
        <div class="row g-4">
          <div class="col-md-6">
            <div class="bg-white shadow-md rounded h-100 p-3" style={{alignItems: "center"}}>
              <div class="featured-box-icon text-primary" style={{alignItems: "center" , padding:'40px' , paddingLeft:'80px' , display:'flex' , justifyContent: 'center'}}>
                {" "}
                <i
                  class=" fa fa-check-circle"
                  style={{ color: "green", fontSize: "350px" }}
                ></i>
              </div>
              <div style={{align: "center" ,display:'flex' , justifyContent: 'center'}}>
                <p style={{ color: "green", fontSize: "30px"}}>Payment Successful.</p>
              </div>

              {/* <iframe
                class="h-100 w-100"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3151.840107317064!2d144.955925!3d-37.817214!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb6899234e561db11!2sEnvato!5e0!3m2!1sen!2sin!4v1530885071349"
                allowfullscreen
              ></iframe> */}
            </div>
          </div>
          <div class="col-md-6">
            <div class="bg-white shadow-md rounded p-4">
              <h2 class="text-6 mb-4">Details</h2>
              <hr class="mx-n4 mb-4" />
              <p class="text-3">
                {successMessage}
              </p>
              <hr class="mx-n4 mb-4" />
              <p class="text-3">
                Check your Email and Phone for confirmation message.
              </p>
             <p className="text-3">
                You can get your ticket printed at railway station.
             </p>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
}
