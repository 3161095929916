import React, { useEffect, useRef, useState } from "react";


export default function Invoice(props) {
    useEffect(() =>{
        // props.setNoHeader(true);
    },[])
  return (
    <>
      <div class="container-fluid invoice-container">
        <div class="row align-items-center">
          <div class="col-sm-7 text-center text-sm-start">
            <p>
              <img src="images/logo.png" title="Quickai" />
            </p>
          </div>
          <div class="col-sm-5 text-center text-sm-end">
            <h4 class="mb-0">Invoice</h4>
            <p class="mb-0">Invoice Number - 16835</p>
          </div>
        </div>
        <hr />
        <p class="text-1 text-center text-muted">
          This e-ticket will only be valid along with an ID proof in original.
          if found travelling without ID proof, passenger will be treated as
          without ticket and charged as per extant Railway rules.
        </p>
        <h4 class="text-4">Journey Details</h4>
        <div class="table-responsive">
          <table class="table table-bordered text-1 table-sm table-striped">
            <thead>
              <tr>
                <td colspan="4" class="">
                  <span class="fw-600">Reference ID</span>: OUJICE{" "}
                  <span class="float-end">
                    <span class="fw-600">Date of Booking</span>: 05 Aug, 2019 at
                    06:50 PM
                  </span>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="fw-600 col-2">Transaction ID</td>
                <td class="col-4">3912912704</td>
                <td class="fw-600 col-2">PNR</td>
                <td class="col-4">2213335256</td>
              </tr>
              <tr>
                <td class="fw-600">Train No & Name</td>
                <td>4211 / Intercity Exp.</td>
                <td class="fw-600">Date of Journey</td>
                <td>10 Aug, 2019</td>
              </tr>
              <tr>
                <td class="fw-600">Class</td>
                <td>Ac Chair</td>
                <td class="fw-600">Date of Boarding</td>
                <td>10 Aug, 2019</td>
              </tr>
              <tr>
                <td class="fw-600">From</td>
                <td>Delhi</td>
                <td class="fw-600">To</td>
                <td>Ahmedabad</td>
              </tr>
              <tr>
                <td class="fw-600">Departure</td>
                <td>06:00 AM</td>
                <td class="fw-600">Total Fare</td>
                <td>$1195.00</td>
              </tr>
              <tr>
                <td class="fw-600">Distance</td>
                <td>946 km</td>
                <td class="fw-600">Quota</td>
                <td>General (GN)</td>
              </tr>
              <tr>
                <td class="fw-600">Adult</td>
                <td>1</td>
                <td class="fw-600">Child</td>
                <td>0</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4 class="text-4 mt-2">Passenger Details</h4>
        <div class="table-responsive">
          <table class="table table-bordered text-1 table-sm">
            <thead>
              <tr>
                <th>S. NO.</th>
                <th>Name</th>
                <th>Age</th>
                <th>Gender</th>
                <th>Seat No.</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Neil Patel</td>
                <td>31</td>
                <td>Male</td>
                <td>C2, 25</td>
                <td>Confirm</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-responsive">
          <table class="table table-bordered text-1 table-sm">
            <tbody>
              <tr>
                <td class="col-4">
                  <span class="fw-600">Agent:</span> Quickai Inc
                </td>
                <td class="col-4">
                  <span class="fw-600">Phone No:</span> 9898989898
                </td>
                <td class="col-4">
                  <span class="fw-600">Email Id:</span> info@quickai.gmail.com
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <span class="fw-600">Adress:</span> 4th Floor, Plot No.22,
                  Above Public Park, 145 Murphy Canyon Rd, Suite 100-18, San
                  Diego CA 2028.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4 class="text-4 mt-2">Fare Details</h4>
        <div class="table-responsive">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td class="col-9 fw-500 text-end">
                  <strong>Basic Fare:</strong>
                </td>
                <td class="col-3 text-end">$980.00</td>
              </tr>
              <tr>
                <td class="col-9 fw-500 text-end">
                  <strong>Reservation Charge:</strong>
                </td>
                <td class="col-3 text-end">$215</td>
              </tr>
              <tr>
                <td class="col-9 fw-500 text-end">
                  <strong>Total Amount:</strong>
                </td>
                <td class="col-3 text-end">$1195.00</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4 class="text-4 mt-2">Important Instruction</h4>
        <ul class="text-1">
          <li>
            One of the passengers in an e-ticket should carry proof of
            identification during the train journey.
          </li>
          <li>
            The input for the proof of identity in case of cancellation/partial
            cancellation is also not required now.
          </li>
          <li>
            The passenger should also carry the Electronic Reservation Slip
            (ERS) during the train journey failing which a penalty of $10 will
            be charged by the TTE/Conductor Guard.
          </li>
        </ul>
        <hr />
        <p class="text-center">
          <strong>Quickai Inc.</strong>
          <br />
          4th Floor, Plot No.22, Above Public Park, 145 Murphy Canyon Rd,
          <br />
          Suite 100-18, San Diego CA 2028.{" "}
        </p>
        <hr />
        <p class="text-center text-1">
          <strong>NOTE :</strong> This is computer generated receipt and does
          not require physical signature.
        </p>
        <div class="text-center">
          <div class="btn-group btn-group-sm d-print-none">
            {" "}
            <a
              href="javascript:window.print()"
              class="btn btn-light border text-black-50 shadow-none"
            >
              <i class="fa fa-print"></i> Print
            </a>{" "}
            <a href="#" class="btn btn-light border text-black-50 shadow-none">
              <i class="fa fa-download"></i> Download
            </a>{" "}
          </div>
        </div>
      </div>
      <p class="text-center d-print-none">
        <a href="#">&laquo; Back to My Account</a>
      </p>
    </>
  );
}
