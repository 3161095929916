import React, { useState, useEffect } from "react";
import "./seats.css";
import "./responsive-seats.css";
import $ from "jquery";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// custom action imports
import { getTicketFare, getVacantSeats } from "../../actions/tickets.actions";
import { getTrainServiceFee } from "../../actions/trains.actions";


export default function ConfirmTicketDetails({ coachData }) {
  const navigate  = useNavigate()
  const [clickedSeat, setClickedSeat] = useState("");
  const [isSeatModalOpen, SetisSeatModalOpen] = useState(false);
  const [bookedSeats, setBookedSeats] = useState([]);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [ticketFare, setTicketFare] = useState(0);
  const [serviceFee, setServiceFee] = useState(50);

  // Utility Functions start
  const removeSelectedSeat = (element) => {
    setSelectedSeats((current) =>
      current.filter((seat) => seat !== element)
    );
  };

  function markBookedSeatsRed() {
    
    for (let seat of bookedSeats) {
      $(`label[for="${seat}"]`).addClass("red");
    }
    
  }

  const handleSeatClick = (seat_number) => {
    setClickedSeat(seat_number);
    SetisSeatModalOpen(true);
    setTimeout(() => {
      SetisSeatModalOpen(false);
    }, 200);
    if (selectedSeats.includes(seat_number)) {
      removeSelectedSeat(seat_number);
    } else {
      if (selectedSeats.length === 8) {
        toast.error("You can select 8 seats at max.");
        $(`#${seat_number}`).prop("checked", false);
      } else {
        setSelectedSeats((prevValue) => {
          return [...prevValue, seat_number];
        });
      }
    }
  };

  function proceedToPayment(){
    if(selectedSeats.length > 0 && ticketFare > 0){
      navigate("/payment" , {state: {
          ticketFare,
          serviceFee,
          seats: selectedSeats,
          train_id: coachData.train_id,
          st_city: coachData.st_city,
          end_city: coachData.ending_city,
          tc_id: coachData.id
        }});
    }else{
      toast.error("Please select a seat")
    }
    
  }
  // Utility Functions end

  // Fetch Functions Start
  async function fetchTicketFare() {
    let resp = await getTicketFare({
      train_id: coachData.train_id,
      st_city: coachData.st_city,
      end_city: coachData.ending_city,
      seat_number: selectedSeats.join(","),
      tc_id: coachData.id,
    });
    if (resp.status === 200) {
      setTicketFare(resp.data.fare);
    } else {
      toast.error("Unable to fetch fares at the moment.");
      setTicketFare(0);
    }
  }
  async function fetchVacantSeats() {
    let resp = await getVacantSeats({
      tc_id: coachData.id,
      st_city: coachData.st_city,
      end_city: coachData.ending_city,
    });
    if(resp.status  === 200){
      let arr2 = resp.data.difference;
      let difference = layoutSeats.filter((x) => !arr2.includes(x));
      setBookedSeats(difference);
      // alert(difference.length)
    }else{
      toast.error("Unable to book seat at the moment. Please try again.")
      navigate("/")
    }
    $("#preloader").delay(333).fadeOut("slow");
  }

  async function fetchTrainServiceFee(){
    let resp = await getTrainServiceFee(coachData.train_id)
    if(resp.status  === 200){
      setServiceFee(Number(resp.data.value))
    }else{
      toast.error("Unable to book seat at the moment. Please try again.")
      navigate("/")
    }
  }
  //Fetch Functions End

  useEffect(() => {
    fetchVacantSeats()
    fetchTrainServiceFee()
    $("#preloader").delay(333).fadeIn("slow");
  }, []);

  useEffect(() => {
    if (selectedSeats.length > 0) {
      fetchTicketFare();
    }else{
      setTicketFare(0)
    }
    
  }, [selectedSeats]);

  useEffect(() => {
    if (bookedSeats.length > 0) {
      markBookedSeatsRed();
    }
  }, [bookedSeats]);

  return (
    <div id="content">
      {/* <!-- Seat Number Modal --> */}
      <div
        className="uniqclvalue"
        style={{ display: isSeatModalOpen ? "flex" : "none" }}
        id="uniqclvalue"
      >
        <h4 id="displayvalue">{clickedSeat}</h4>
      </div>
      {/* <!-- Seat Number Modal End -->  */}
      <section className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="bg-white shadow-md rounded p-3 p-sm-4 confirm-details">
              <h2 className="text-6 mb-3 mb-sm-4">Confirm Trains Details</h2>
              <hr className="mx-n3 mx-sm-n4 mb-4" />
              <div class="custom-seats">
                <div class="seats-header">
                  <div class="seat-heading">
                    <h2 class="path">Path</h2>
                  </div>
                </div>
                <div class="path-line">
                  <div class="box1"></div>
                  <div class="box2"></div>
                  <div class="box3">
                    <h3 class="entrance">ENTRANCE</h3>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box"></div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="4S"
                        type="checkbox"
                        onClick={() => handleSeatClick("4S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="4S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        <div class="clvalue">4S</div>
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="3S"
                        type="checkbox"
                        onClick={() => handleSeatClick("3S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="3S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        <div class="clvalue">3S</div>
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="2S"
                        type="checkbox"
                        onClick={() => handleSeatClick("2S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="2S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        <div class="clvalue">2S</div>
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="1S"
                        type="checkbox"
                        onClick={() => handleSeatClick("1S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="1S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        <div class="clvalue">1S</div>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="cabin-box">
                  <div class="box"></div>
                  <div class="box">
                    <h3 class="cabin">Cabin</h3>
                    <h3 class="tablee">Table</h3>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="81S"
                        type="checkbox"
                        onClick={() => handleSeatClick("81S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="81S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        <div class="clvalue">81S</div>
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="8S"
                        type="checkbox"
                        onClick={() => handleSeatClick("8S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="8S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        8S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="7S"
                        type="checkbox"
                        onClick={() => handleSeatClick("7S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="7S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        7S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="6S"
                        type="checkbox"
                        onClick={() => handleSeatClick("6S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="6S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        6S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="5S"
                        type="checkbox"
                        onClick={() => handleSeatClick("5S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="5S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        5S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="82S"
                        type="checkbox"
                        onClick={() => handleSeatClick("82S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="82S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        82S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="12S"
                        type="checkbox"
                        onClick={() => handleSeatClick("12S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="12S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        12S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="11S"
                        type="checkbox"
                        onClick={() => handleSeatClick("11S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="11S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        11S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="10S"
                        type="checkbox"
                        onClick={() => handleSeatClick("10S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="10S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        10S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="9S"
                        type="checkbox"
                        onClick={() => handleSeatClick("9S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="9S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        9S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="cabin-box">
                  <div class="box"></div>
                  <div class="box">
                    <h3 class="cabin">Cabin</h3>
                    <h3 class="tablee">Table</h3>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="83S"
                        type="checkbox"
                        onClick={() => handleSeatClick("83S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="83S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        83S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="16S"
                        type="checkbox"
                        onClick={() => handleSeatClick("16S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="16S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        16S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="15S"
                        type="checkbox"
                        onClick={() => handleSeatClick("15S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="15S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        15S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="14S"
                        type="checkbox"
                        onClick={() => handleSeatClick("14S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="14S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        14S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="13S"
                        type="checkbox"
                        onClick={() => handleSeatClick("13S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="13S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        13S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="84S"
                        type="checkbox"
                        onClick={() => handleSeatClick("84S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="84S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        84S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="20S"
                        type="checkbox"
                        onClick={() => handleSeatClick("20S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="20S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        20S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="19S"
                        type="checkbox"
                        onClick={() => handleSeatClick("19S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="19S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        19S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="18S"
                        type="checkbox"
                        onClick={() => handleSeatClick("18S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="18S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        18S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="17S"
                        type="checkbox"
                        onClick={() => handleSeatClick("17S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="17S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        17S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="cabin-box">
                  <div class="box"></div>
                  <div class="box">
                    <h3 class="cabin">Cabin</h3>
                    <h3 class="tablee">Table</h3>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="85S"
                        type="checkbox"
                        onClick={() => handleSeatClick("85S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="85S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        85S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="24S"
                        type="checkbox"
                        onClick={() => handleSeatClick("24S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="24S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        24S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="23S"
                        type="checkbox"
                        onClick={() => handleSeatClick("23S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="23S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        23S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="22S"
                        type="checkbox"
                        onClick={() => handleSeatClick("22S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="22S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        22S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="21S"
                        type="checkbox"
                        onClick={() => handleSeatClick("21S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="21S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        21S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="86S"
                        type="checkbox"
                        onClick={() => handleSeatClick("86S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="86S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        86S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="28S"
                        type="checkbox"
                        onClick={() => handleSeatClick("28S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="28S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        28S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="27S"
                        type="checkbox"
                        onClick={() => handleSeatClick("27S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="27S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        27S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="26S"
                        type="checkbox"
                        onClick={() => handleSeatClick("26S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="26S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        26S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="25S"
                        type="checkbox"
                        onClick={() => handleSeatClick("25S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="25S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        25S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="cabin-box">
                  <div class="box"></div>
                  <div class="box">
                    <h3 class="cabin">Cabin</h3>
                    <h3 class="tablee">Table</h3>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="87S"
                        type="checkbox"
                        onClick={() => handleSeatClick("87S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="87S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        87S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="32S"
                        type="checkbox"
                        onClick={() => handleSeatClick("32S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="32S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        32S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="31S"
                        type="checkbox"
                        onClick={() => handleSeatClick("31S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="31S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        31S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="30S"
                        type="checkbox"
                        onClick={() => handleSeatClick("30S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="30S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        30S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="29S"
                        type="checkbox"
                        onClick={() => handleSeatClick("29S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="29S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        29S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="88S"
                        type="checkbox"
                        onClick={() => handleSeatClick("88S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="88S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        88S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="36S"
                        type="checkbox"
                        onClick={() => handleSeatClick("36S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="36S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        36S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="35S"
                        type="checkbox"
                        onClick={() => handleSeatClick("35S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="35S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        35S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="34S"
                        type="checkbox"
                        onClick={() => handleSeatClick("34S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="34S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        34S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="33S"
                        type="checkbox"
                        onClick={() => handleSeatClick("33S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="33S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        33S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="cabin-box">
                  <div class="box"></div>
                  <div class="box">
                    <h3 class="cabin">Cabin</h3>
                    <h3 class="tablee">Table</h3>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="89S"
                        type="checkbox"
                        onClick={() => handleSeatClick("89S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="89S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        89S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="40S"
                        type="checkbox"
                        onClick={() => handleSeatClick("40S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="40S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        40S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="39S"
                        type="checkbox"
                        onClick={() => handleSeatClick("39S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="39S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        39S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="38S"
                        type="checkbox"
                        onClick={() => handleSeatClick("38S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="38S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        38S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="37S"
                        type="checkbox"
                        onClick={() => handleSeatClick("37S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="37S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        37S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="90S"
                        type="checkbox"
                        onClick={() => handleSeatClick("90S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="90S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        90S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="44S"
                        type="checkbox"
                        onClick={() => handleSeatClick("44S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="44S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        44S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="43S"
                        type="checkbox"
                        onClick={() => handleSeatClick("43S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="43S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        43S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="42S"
                        type="checkbox"
                        onClick={() => handleSeatClick("42S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="42S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        42S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="41S"
                        type="checkbox"
                        onClick={() => handleSeatClick("41S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="41S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        41S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="cabin-box">
                  <div class="box"></div>
                  <div class="box">
                    <h3 class="cabin">Cabin</h3>
                    <h3 class="tablee">Table</h3>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="91S"
                        type="checkbox"
                        onClick={() => handleSeatClick("91S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="91S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        91S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="48S"
                        type="checkbox"
                        onClick={() => handleSeatClick("48S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="48S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        48S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="47S"
                        type="checkbox"
                        onClick={() => handleSeatClick("47S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="47S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        47
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="46S"
                        type="checkbox"
                        onClick={() => handleSeatClick("46S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="46S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        46S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="45S"
                        type="checkbox"
                        onClick={() => handleSeatClick("45S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="45S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        45S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="92S"
                        type="checkbox"
                        onClick={() => handleSeatClick("92S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="92S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        92S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="52S"
                        type="checkbox"
                        onClick={() => handleSeatClick("52S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="52S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        52S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="51S"
                        type="checkbox"
                        onClick={() => handleSeatClick("51S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="51S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        51S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="50S"
                        type="checkbox"
                        onClick={() => handleSeatClick("50S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="50S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        50S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="49S"
                        type="checkbox"
                        onClick={() => handleSeatClick("49S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="49S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        49S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="cabin-box">
                  <div class="box"></div>
                  <div class="box">
                    <h3 class="cabin">Cabin</h3>
                    <h3 class="tablee">Table</h3>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="93S"
                        type="checkbox"
                        onClick={() => handleSeatClick("93S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="93S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        93S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="56S"
                        type="checkbox"
                        onClick={() => handleSeatClick("56S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="56S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        56S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="55S"
                        type="checkbox"
                        onClick={() => handleSeatClick("55S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="55S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        55S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="54S"
                        type="checkbox"
                        onClick={() => handleSeatClick("54S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="54S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        54S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="53S"
                        type="checkbox"
                        onClick={() => handleSeatClick("53S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="53S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        53S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="94S"
                        type="checkbox"
                        onClick={() => handleSeatClick("94S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="94S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        94S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="60S"
                        type="checkbox"
                        onClick={() => handleSeatClick("60S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="60S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        60S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="59S"
                        type="checkbox"
                        onClick={() => handleSeatClick("59S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="59S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        59S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="58S"
                        type="checkbox"
                        onClick={() => handleSeatClick("58S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="58S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        58S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="57S"
                        type="checkbox"
                        onClick={() => handleSeatClick("57S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="57S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        57S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="cabin-box">
                  <div class="box"></div>
                  <div class="box">
                    <h3 class="cabin">Cabin</h3>
                    <h3 class="tablee">Table</h3>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="95S"
                        type="checkbox"
                        onClick={() => handleSeatClick("95S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="95S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        95S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="64S"
                        type="checkbox"
                        onClick={() => handleSeatClick("64S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="64S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        64S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="63S"
                        type="checkbox"
                        onClick={() => handleSeatClick("63S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="63S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        63S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="62S"
                        type="checkbox"
                        onClick={() => handleSeatClick("62S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="62S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        62S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="61S"
                        type="checkbox"
                        onClick={() => handleSeatClick("61S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="61S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        61
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="96S"
                        type="checkbox"
                        onClick={() => handleSeatClick("96S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="96S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        96S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="68S"
                        type="checkbox"
                        onClick={() => handleSeatClick("68S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="68S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        68S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="67S"
                        type="checkbox"
                        onClick={() => handleSeatClick("67S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="67S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        67S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="66S"
                        type="checkbox"
                        onClick={() => handleSeatClick("66S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="66S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        66S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="65S"
                        type="checkbox"
                        onClick={() => handleSeatClick("65S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="65S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        65S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="cabin-box">
                  <div class="box"></div>
                  <div class="box">
                    <h3 class="cabin">Cabin</h3>
                    <h3 class="tablee">Table</h3>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="97S"
                        type="checkbox"
                        onClick={() => handleSeatClick("97S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="97S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        97S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="72S"
                        type="checkbox"
                        onClick={() => handleSeatClick("72S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="72S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        72S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="71S"
                        type="checkbox"
                        onClick={() => handleSeatClick("71S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="71S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        71S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="70S"
                        type="checkbox"
                        onClick={() => handleSeatClick("70S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="70S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        70S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="69S"
                        type="checkbox"
                        onClick={() => handleSeatClick("69S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="69S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        69S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="98S"
                        type="checkbox"
                        onClick={() => handleSeatClick("98S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="98S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        98S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="76S"
                        type="checkbox"
                        onClick={() => handleSeatClick("76S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="76S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        76S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="75S"
                        type="checkbox"
                        onClick={() => handleSeatClick("75S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="75S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        75S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="74S"
                        type="checkbox"
                        onClick={() => handleSeatClick("74S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="74S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        74S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="73S"
                        type="checkbox"
                        onClick={() => handleSeatClick("73S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="73S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        73S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="cabin-box">
                  <div class="box"></div>
                  <div class="box">
                    <h3 class="cabin">Cabin</h3>
                    <h3 class="tablee">Table</h3>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1"></div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="80S"
                        type="checkbox"
                        onClick={() => handleSeatClick("80S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="80S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        80S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="79S"
                        type="checkbox"
                        onClick={() => handleSeatClick("79S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="79S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        79S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="78S"
                        type="checkbox"
                        onClick={() => handleSeatClick("78S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="78S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        78S
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="77S"
                        type="checkbox"
                        onClick={() => handleSeatClick("77S")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="77S"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/seat.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        77S
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>

                <div class="cabin-box">
                  <div class="box"></div>
                  <div class="box">
                    <h3 class="cabin">Cabin</h3>
                    <h3 class="tablee">Table</h3>
                  </div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="UP1B"
                        type="checkbox"
                        onClick={() => handleSeatClick("UP1B")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="UP1B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        UP1B
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="UP2B"
                        type="checkbox"
                        onClick={() => handleSeatClick("UP2B")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="UP2B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        UP2B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="UP3B"
                        type="checkbox"
                        onClick={() => handleSeatClick("UP3B")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="UP3B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        UP3B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="UP4B"
                        type="checkbox"
                        onClick={() => handleSeatClick("UP4B")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="UP4B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        UP4B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="UP5B"
                        type="checkbox"
                        onClick={() => handleSeatClick("UP5B")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="UP5B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        UP5B
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="UP6B"
                        type="checkbox"
                        onClick={() => handleSeatClick("UP6B")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="UP6B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        UP6B
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="UP7B"
                        type="checkbox"
                        onClick={() => handleSeatClick("UP7B")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="UP7B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        UP7B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="UP8B"
                        type="checkbox"
                        onClick={() => handleSeatClick("UP8B")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="UP8B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        UP8B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="UP9B"
                        type="checkbox"
                        onClick={() => handleSeatClick("UP9B")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="UP9B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        UP9B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="UP10B"
                        type="checkbox"
                        onClick={() => handleSeatClick("UP10B")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="UP10B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        UP10B
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="UP11B"
                        type="checkbox"
                        onClick={() => handleSeatClick("UP11B")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="UP11B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        UP11B
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="UP12B"
                        type="checkbox"
                        onClick={() => handleSeatClick("UP12B")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="UP12B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        UP12B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="UP13B"
                        type="checkbox"
                        onClick={() => handleSeatClick("UP13B")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="UP13B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        UP13B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="UP14B"
                        type="checkbox"
                        onClick={() => handleSeatClick("UP14B")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="UP14B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        UP14B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="UP15B"
                        type="checkbox"
                        onClick={() => handleSeatClick("UP15B")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="UP15B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        UP15B
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
                <div class="seats-grid">
                  <div class="seat-box seat-box-1">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="UP16B"
                        type="checkbox"
                        onClick={() => handleSeatClick("UP16B")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="UP16B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        UP16B
                      </label>
                    </div>
                  </div>
                  <div class="seat-box">
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="UP17B"
                        type="checkbox"
                        onClick={() => handleSeatClick("UP17B")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="UP17B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        UP17B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="UP18B"
                        type="checkbox"
                        onClick={() => handleSeatClick("UP18B")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="UP18B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        UP18B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="UP19B"
                        type="checkbox"
                        onClick={() => handleSeatClick("UP19B")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="UP19B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        UP19B
                      </label>
                    </div>
                    <div class="seat">
                      <input
                        class="cl-custom-check"
                        id="UP20B"
                        type="checkbox"
                        onClick={() => handleSeatClick("UP20B")}
                      />
                      <label
                        class="cl-custom-check-label"
                        for="UP20B"
                        title="Click for select/unselect"
                      >
                        <div class="img">
                          <img
                            src="./images/bed.png"
                            alt="seat"
                            class="img-fluid"
                          />
                        </div>
                        UP20B
                      </label>
                    </div>
                  </div>
                  <div class="seat-box"></div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Side Panel (Fare Details)
          ============================================= --> */}

          <aside className="col-lg-4 mt-4 mt-lg-0">
            <div className="bg-white shadow-md rounded p-3">
              <h3 className="text-5 mb-3">Fare Details</h3>
              <hr className="mx-n3" />
              <ul className="list-unstyled">
                <li className="mb-2">
                  Selected Seats
                  <span className="float-end text-3 fw-500 text-dark">
                    {selectedSeats.join(", ")}
                  </span>
                  <br />
                  <small className="text-muted">{selectedSeats.length}</small>
                </li>
                <li className="mb-2">
                  Base Fare
                  <span className="float-end text-4 fw-500 text-dark">
                    PKR{ticketFare}
                  </span>
                </li>
                <li className="mb-2">
                  Service Fee{" "}
                  <span className="float-end text-4 fw-500 text-dark">
                    PKR{(selectedSeats.length > 0 ? serviceFee : 0)}
                  </span>
                </li>
              </ul>
              <div className="text-dark bg-light-4 text-4 fw-600 p-3">
                {" "}
                Total Amount{" "}
                <span className="float-end text-6">
                  PKR{ticketFare + (selectedSeats.length > 0 ? serviceFee : 0)}
                </span>{" "}
              </div>
              {/* <h3 className="text-4 mb-3 mt-4">Promo Code</h3>
              <div className="input-group mb-4">
                <input
                  className="form-control"
                  placeholder="Promo Code"
                  aria-label="Promo Code"
                  type="text"
                />
                <button
                  className="btn btn-secondary shadow-none px-3"
                  type="submit"
                >
                  APPLY
                </button>
              </div>
              <ul className="promo-code">
                <li>
                  <span className="d-block text-3 fw-600">FLTOFFER</span>Up to
                  $500 Off on your booking. Hurry! Limited period offer.{" "}
                  <a className="text-1" href="#">
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <span className="d-block text-3 fw-600">HOTOFFER</span>Up to
                  $500 Off on your booking. Hurry! Limited period offer.{" "}
                  <a className="text-1" href="#">
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <span className="d-block text-3 fw-600">SUMMEROFFER</span>Up
                  to $500 Off on your booking. Hurry! Limited period offer.{" "}
                  <a className="text-1" href="#">
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <span className="d-block text-3 fw-600">BIGOFFER</span>Up to
                  $500 Off on your booking. Hurry! Limited period offer.{" "}
                  <a className="text-1" href="#">
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <span className="d-block text-3 fw-600">FLTOFFER</span>Up to
                  $500 Off on your booking. Hurry! Limited period offer.{" "}
                  <a className="text-1" href="#">
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <span className="d-block text-3 fw-600">FLTOFFER</span>Up to
                  $500 Off on your booking. Hurry! Limited period offer.{" "}
                  <a className="text-1" href="#">
                    Terms & Conditions
                  </a>
                </li>
              </ul> */}
              <div className="d-grid mt-3">
                <button
                  className="btn btn-primary"
                  onclick="location.href='payment.html';"
                  type="button"
                  onClick={()=>{proceedToPayment()}}
                >
                  Proceed To Payment
                </button>
              </div>
            </div>
          </aside>
          {/* <!-- Side Panel end -->  */}
        </div>
      </section>
    </div>
  );
}


const layoutSeats = ["4S","3S","2S","1S","81S","8S","7S","6S","5S","82S","12S","11S","10S","9S","83S","16S","15S","14S","13S","84S","20S","19S","18S","17S","85S","24S","23S","22S","21S","86S","28S","27S","26S","25S","87S","32S","31S","30S","29S","88S","36S","35S","34S","33S","89S","40S","39S","38S","37S","90S","44S","43S","42S","41S","91S","48S","47S","46S","45S","92S","52S","51S","50S","49S","93S","56S","55S","54S","53S","94S","60S","59S","58S","57S","95S","64S","63S","62S","61S","96S","68S","67S","66S","65S","97S","72S","71S","70S","69S","98S","76S","75S","74S","73S","80S","79S","78S","77S","UP1B","UP2B","UP3B","UP4B","UP5B","UP6B","UP7B","UP8B","UP9B","UP10B","UP11B","UP12B","UP13B","UP14B","UP15B","UP16B","UP17B","UP18B","UP19B","UP20B"]
