import React, { useRef, useState, useEffect } from "react";
import { Formik, Form, Field, useFormik } from "formik";
import * as Yup from "yup";
import valid from "card-validator";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  addPayment,
  helloTest,
  getPaymentObject,
} from "../../actions/payment.actions";
import {createOneTicket} from "../../actions/tickets.actions"
import { sendOtp, verifyOtp } from "../../actions/customers.actions";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Countdown from "react-countdown";
import $ from "jquery";
import { v4 as uuidv4 } from 'uuid';

export default function Payment() {
  const location = useLocation();
  const navigate = useNavigate();
  const [otp, setOtp] = useState();
  const [sendOtpEnabled, setSendOtpEnabled] = useState(true);
  const [otpVerified, setOtpVerified] = useState(false);
  const [customerVerified, setCustomerVerified] = useState(false);
  const [locationData, setLocationData] = useState({
    ticketFare: 0,
    serviceFee: 0,
    seats: [],
  });
  const [paymentForm, setPaymentForm] = useState({});

  async function loadFormObject(form, type) {
    let paymentObject = await getPaymentObject({ab:123});
    setPaymentForm(paymentObject.data);
  }

  const countdownRenderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      setSendOtpEnabled(true);
      // Render a completed state
      return "Send OTP";
    } else {
      // Render a countdown
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      cnic: "",
    },
    validationSchema: customerSchema,
    onSubmit: (values, { resetForm }) => {
      handlePaymentSubmit(values);
    },
  });

  async function sendCustomerOtp() {
    if (formik.values.phone.length != 11) {
      toast.error("Invalid Phone Number");
      return;
    }
    setSendOtpEnabled(false);
    let sentOtp = await sendOtp({
      phone: formik.values.phone,
    });

    if (sentOtp.status === 200) {
      toast.success("OTP sent successfully.!");
    } else {
      toast.error("Failed to send OTP. Check phone number or try again");
      setSendOtpEnabled(true);
    }
  }

  async function verifyCustomerOtp() {
    if (formik.values.phone.length != 11) {
      toast.error("Invalid Phone Number");
      return;
    }

    let verifiedOtp = await verifyOtp({
      phone: formik.values.phone,
      otp,
      type: "newBooking",
    });
    if (verifiedOtp.status === 200) {
      return true;
    } else {
      toast.error("Invalid OTP or phone. Check phone or try again");
      return false;
    }
  }

  async function handlePaymentSubmit(values){
    $("#preloader").delay(333).fadeIn("slow");
    let resp = await createOneTicket({...values , ...locationData});
    if(resp.status === 200){
      // alert(resp.data.customer.id)
      setPaymentForm(resp.data);  
    }
    // alert("done")
    console.log(resp)
    // let isOtpVerified = await verifyCustomerOtp()
    // if(isOtpVerified){
    //   alert('Payments are disabled')
    //   await loadFormObject()
    // }
  }

  useEffect(() => {
    if (
      location.state &&
      location.state.seats &&
      location.state.ticketFare
    ) {
      setLocationData(location.state);
      console.log('payment state is', location.state)
    } else {
      toast.error("Invalid Ticket Data");
      navigate("/");
    }
  }, [location]);

  useEffect(()=>{
    if(paymentForm.pp_Amount && paymentForm.pp_Amount !== 0){
      $('#hidden-payment-button').click()
    }
  },[paymentForm])

  useEffect(()=>{
    console.log("location data is", locationData)
  },[locationData])

  return (
    <div id="content">
      <div class="container">
        <div class="bg-white shadow-md rounded p-4">
          <h3 class="text-6 mb-4">Proceed to Payment</h3>

          <hr class="mx-n4" />

          <div class="row g-4">
            <div class="col-md-7 col-lg-8 order-1 order-md-0">
              <h3 class="text-4 mb-4">Please enter your details</h3>
              {/* <ul class="nav nav-tabs mb-4" id="myTab" role="tablist">
                <li class="nav-item">
                  {" "}
                  <a
                    class="nav-link text-4 lh-lg active"
                    id="first-tab"
                    data-bs-toggle="tab"
                    href="#firstTab"
                    role="tab"
                    aria-controls="firstTab"
                    aria-selected="true"
                  >
                    Credit/Debit Cards
                  </a>{" "}
                </li>
                <li class="nav-item">
                  {" "}
                  <a
                    class="nav-link text-4 lh-lg"
                    id="second-tab"
                    data-bs-toggle="tab"
                    href="#secondTab"
                    role="tab"
                    aria-controls="secondTab"
                    aria-selected="false"
                  >
                    JazzCash
                  </a>{" "}
                </li>
              </ul> */}
              {/* <button onClick={TestHello}>Clicl Me</button> */}
              <div class="tab-content my-3" id="myTabContent">
                <Box component="form" onSubmit={formik.handleSubmit} noValidate>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <TextField
                        name="name"
                        label="Name"
                        placeholder="Name"
                        fullWidth
                        variant="filled"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                        helperText={formik.touched.name && formik.errors.name}
                      />
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <TextField
                        name="email"
                        label="Email"
                        placeholder="Email"
                        fullWidth
                        variant="filled"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <TextField
                        name="cnic"
                        label="CNIC"
                        placeholder="CNIC"
                        fullWidth
                        variant="filled"
                        value={formik.values.cnic}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.cnic && Boolean(formik.errors.cnic)
                        }
                        helperText={formik.touched.cnic && formik.errors.cnic}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <TextField
                        name="phone"
                        label="Phone"
                        placeholder="Phone"
                        fullWidth
                        variant="filled"
                        disabled={!sendOtpEnabled || otpVerified}
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.phone && Boolean(formik.errors.phone)
                        }
                        helperText="Enter number like 03*********"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <button
                        className="btn btn-primary btn-small"
                        style={{ minWidth: "100%" }}
                        type="button"
                        disabled={!sendOtpEnabled || otpVerified}
                        onClick={() => sendCustomerOtp()}
                      >
                        {sendOtpEnabled ? (
                          "Send OTP"
                        ) : (
                          <Countdown
                            date={Date.now() + 30000}
                            renderer={countdownRenderer}
                          />
                        )}
                      </button>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <TextField
                        name="otp"
                        label="Otp"
                        placeholder="OTP"
                        fullWidth
                        variant="filled"
                        disabled={otpVerified}
                        value={otp}
                        onChange={(e) => {
                          setOtp(e.target.value);
                        }}

                        // helperText={formik.touched.phone && formik.errors.phone}
                      />
                    </Grid>

                    {/* <Grid item lg={3} md={3} sm={3} xs={6}>
                      <button
                        className="btn btn-success btn-small"
                        onClick={() => verifyCustomerOtp()}
                        disabled={otpVerified}
                        type="button"
                      >
                        Verify OTP
                      </button>
                    </Grid> */}
                    <div class="col-12 d-grid mt-3">
                      {" "}
                      <button
                        class="btn btn-lg btn-success"
                        // style={{ color:'' }}
                        // onClick={() => verifyCustomerOtp()}
                        // disabled={otpVerified}
                        type="submit"
                      >
                        Proceed to Pay
                      </button>
                      
                    </div>
                  </Grid>
                </Box>
                <form
                  method="post"
                  action="https://payments.jazzcash.com.pk/CustomerPortal/transactionmanagement/merchantform"
                  style={{ width: "100%" }}
                >
                  {Object.keys(paymentForm).map((key) => (
                    <input type="hidden" name={key} value={paymentForm[key]} key={uuidv4()}/>
                  ))}

                  <div class="col-12 d-grid mt-3">
                    {" "}
                    <button
                      class="btn btn-lg btn-success"
                      id="hidden-payment-button"
                      style={{ display: customerVerified ? "block" : "none" }}
                      type="submit"
                    >
                      Proceed to Pay
                    </button>{" "}
                  </div>
                </form>

                {/* <div
                  class="tab-pane fade"
                  id="secondTab"
                  role="tabpanel"
                  aria-labelledby="second-tab"
                >
                  {" "}
                  <img
                    class="img-fluid"
                    src="images/jazzcash.png"
                    alt="Paypal Logo"
                    title="Pay easily, fast and secure with JazzCash Mobile Wallet."
                  />
                  <p class="lead">
                    Pay easily, fast and secure with JazzCash Mobile Wallet.
                  </p>
                  <Formik
                    initialValues={{
                      mobileNumber: "",
                    }}
                    key={226}
                    validationSchema={jazzCashSchema}
                    onSubmit={(values) => {
                      // same shape as initial values
                      performPayment(values, "jazzcash");
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form>
                        <div class="row g-3">
                          <div class="col-12 ">
                            <label class="form-label" for="mobileNumber">
                              Enter Mobile Account Number
                            </label>
                            <Field
                              name="mobileNumber"
                              className="form-control"
                              placeholder="Mobile Account Number"
                            />
                            {touched.mobileNumber && errors.mobileNumber && (
                              <span className="text-danger">
                                {errors.mobileNumber}
                              </span>
                            )}
                          </div>

                          <div class="col-12 d-grid">
                            {" "}
                            <button
                              class="btn "
                              style={{
                                backgroundColor: "#FCF111",
                                color: "#851A1C",
                              }}
                              type="submit"
                            >
                              Pay with JazzCash
                            </button>{" "}
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div> */}
              </div>
            </div>
            <div class="col-md-5 col-lg-4 order-0 order-md-1">
              {/* <!-- Payable Amount
          ============================================= --> */}
              <div class="bg-light rounded p-4">
                <h3 class="text-4 mb-4">Payable Amount</h3>
                <ul class="list-unstyled">
                  <li class="mb-2">
                    Seats{" "}
                    <span class="float-end text-3 fw-500 text-dark">
                      {locationData.seats.length > 0 && locationData.seats.join(",")}
                    </span>
                  </li>
                  <li class="mb-2">
                    Amount <span class="text-success"></span>{" "}
                    <span class="float-end text-4 fw-500 text-dark">
                      Rs.{locationData.serviceFee + locationData.ticketFare}
                    </span>
                  </li>
                </ul>
                <hr class="mx-n4" />
                <div class="text-dark text-4 fw-500 mt-4">
                  {" "}
                  Total Amount
                  <span class="float-end text-7">
                    Rs.{locationData.serviceFee + locationData.ticketFare}
                  </span>
                </div>
              </div>
              {/* <!-- Payable Amount end -->  */}

              {/* <!-- Privacy Information
			============================================= --> */}
              <div class="bg-light rounded p-4 d-none d-md-block mt-4">
                <h3 class="text-4 mb-3">We value your Privacy.</h3>
                <p class="mb-0">
                  We will not sell or distribute your contact information. Read
                  our <a href="#">Privacy Policy</a>.
                </p>
                <hr class="mx-n4" />
                <h3 class="text-4 mb-3">Billing Enquiries</h3>
                <p class="mb-0">
                  Do not hesitate to reach our <a href="#">support team</a> if
                  you have any queries.
                </p>
              </div>
              {/* <!-- Privacy Information end -->  */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const customerSchema = Yup.object().shape({
  phone: Yup.string()
    .required("Phone is Required")
    .min(11, "Please enter valid phone")
    .max(11, "Please enter valid phone"),
  name: Yup.string()
    .required("Name is required")
    .max(30, "Please enter valid name."),
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  cnic: Yup.string()
    .min(13, "Please enter valid cnic without dashes!")
    .max(13, "Please enter valid cnic without dashes!")
    .required("CNIC is required"),
});

const CardSchema = Yup.object().shape({
  cardNumber: Yup.string()
    .test(
      "test-number", // this is used internally by yup
      "Credit Card number is invalid", //validation message
      (value) => valid.number(value).isValid
    ) // return true false based on validation
    .required("Required")
    .min(16, "Invalid!")
    .max(16, "Invalid!"),
  expiryMonth: Yup.string()
    .test(
      "test-number", // this is used internally by yup
      "Month is invalid", //validation message
      (value) => valid.expirationMonth(value).isValid
    )
    .min(2, "Invalid!")
    .max(2, "Invalid!")
    .required("Required"),
  expiryYear: Yup.string()
    .test(
      "test-number", // this is used internally by yup
      "Year is invalid", //validation message
      (value) => valid.expirationYear(value).isValid
    )
    .min(2, "Invalid!")
    .max(2, "Invalid!")
    .required("Required"),
  cardCvv: Yup.string()
    .test(
      "test-number", // this is used internally by yup
      "CVV is invalid", //validation message
      (value) => valid.cvv(value).isValid
    )
    .min(3, "Invalid!")
    .max(3, "Invalid!")
    .required("Required"),
  cardName: Yup.string().max(50, "Invalid!").required("Required"),
});

const jazzCashSchema = Yup.object().shape({
  mobileNumber: Yup.string()
    .required("Required")
    .min(11, "Invalid!")
    .max(11, "Invalid!"),
});
