import React from "react";

export default function ContactUs() {
  return (
    <div id="content">
      <div class="container">
        <div class="row g-4">
          <div class="col-md-6">
            <div class="bg-white shadow-md rounded h-100 p-3">
              {/* <iframe
                class="h-100 w-100"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3151.840107317064!2d144.955925!3d-37.817214!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb6899234e561db11!2sEnvato!5e0!3m2!1sen!2sin!4v1530885071349"
                allowfullscreen
              ></iframe> */}
            </div>
          </div>
          <div class="col-md-6">
            <div class="bg-white shadow-md rounded p-4">
              <h2 class="text-6 mb-4">Get in touch</h2>
              <hr class="mx-n4 mb-4" />
              <p class="text-3">
                For Customer Support and Query, Get in touch with us:{" "}
                <a href="#">Help</a>
              </p>
              <div class="featured-box style-1">
                <div class="featured-box-icon text-primary">
                  {" "}
                  <i class="fas fa-map-marker-alt"></i>
                </div>
                <h3>Imran Enterprises.</h3>
                <p>
                  Office # 49/50 lahore
                  <br />
                  Khuda Bakhsh Colony
                  <br />
                  Airport Road
                  <br />
                  Lahore , Punjab{" "}
                </p>
              </div>
              <div class="featured-box style-1">
                <div class="featured-box-icon text-primary">
                  {" "}
                  <i class="fas fa-phone"></i>{" "}
                </div>
                <h3>Telephone</h3>
                <p><a href="tel:+923415444470">+92-341-5444470</a></p>
              </div>
              <div class="featured-box style-1">
                <div class="featured-box-icon text-primary">
                  {" "}
                  <i class="fas fa-envelope"></i>{" "}
                </div>
                <h3>Business Inquiries</h3>
                <p>aaentp01@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
